import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../../constants/theme";
import { ArrowLeft, Plus, Trash } from "react-feather";
import HelpSection from "../../../components/HelpSection";
import TextInput from "../../../components/Form/TextInput";
import form, {
  Emitter as courseEmmiter,
  validInfoStrict,
} from "./../../../forms/createNewCourse";
import TextArea from "../../../components/Form/TextArea";
import SelectPicker from "../../../components/Form/SelectPicker";
import MainLesonBlock from "../../../components/BlockMaker/MainLesonBlock";
import { useEffect, useState } from "react";
import { findError2, getUID } from "../../../utils";
import FormErrorContainer from "../../../components/FormErrorContainer";
import Button from "../../../components/Button";
import SocialInput from "../components/SocialInput";

export default function StartPage() {
  const styles = useTheme(dynamicStyle);
  const navigate = useNavigate();
  const formDisabled = false;
  const categories = form.get("_options.categories", {});
  const [valid, setValid] = useState({ error: null });
  const [links, setLinks] = useState(form.get("social_links", []));
  const { id } = useParams();

  const removeLink = ({ id }) => {
    const socialLinks = form.get("social_links");

    form.set(
      "social_links",
      socialLinks.filter((item) => item.id !== id)
    );
  };

  const addLink = () => {
    const socialLinks = form.get("social_links");

    form.set("social_links", [
      ...socialLinks,
      {
        id: getUID(),
        title: void 0,
        link: void 0,
        type: void 0,
      },
    ]);
  };

  useEffect(() => {
    //if (form.link("_start_edit") === true) {
    setValid(validInfoStrict());
    //}

    return courseEmmiter.on("*", (value, key) => {
      if (key === 'social_links') {
        setLinks([...value]);
      }
      setValid(validInfoStrict());
    });
  }, []);

  const goToPreview = () => {
    navigate(`/course/${id}/start_page/preview`)
  }

  return (
    <>
      <div style={styles.title}>
        <ArrowLeft
          onClick={() => navigate(-1)}
          style={styles.backIcon}
          size={32}
        />
        Станица курса
      </div>
      <div>
        <HelpSection id="HelpSection-1">
          Данные с этой страницы будут видеть пользователи при переходе на
          страницу курса
        </HelpSection>

        <div style={styles.label}>Название курса</div>
        <TextInput
          containerStyle={styles.textInputontainerStyle}
          name={"title"}
          collector={form}
          disabled={formDisabled}
        />
        <div style={styles.label}>Описание</div>

        <TextArea
          containerStyle={styles.textInputontainerStyle}
          inputStyle={styles.inputStyle}
          name={"description"}
          collector={form}
          disabled={formDisabled}
        />

        <div style={styles.label}>Категоия</div>

        <SelectPicker
          disabled={formDisabled}
          containerStyle={styles.textInputontainerStyle}
          name={"category"}
          collector={form}
          title={"Категория"}
          items={Object.keys(categories).map((key) => {
            return {
              value: key,
              label: categories[key],
            };
          })}
        />
        <div style={styles.label}>Медиаматериалы</div>

        {findError2(valid, /intro_blocks/) && (
          <FormErrorContainer>
            Значение блоков не должно быть пустым
          </FormErrorContainer>
        )}

        <MainLesonBlock
          containerStyle={styles.mainBlockcontainerStyle}
          disabled={formDisabled}
          buttonText={"Добавить медиаматериалы"}
          form={form}
          emitter={courseEmmiter}
          formField={"intro_blocks"}
          blocks={["photo", "video"]}
          formType={"createNewCourse"}
        />

        {links
          .filter((a) => a !== undefined)
          .map((item, index) => {
            return (
              <SocialInput
                containerStyle={{ marginTop: 8 }}
                key={item.id}
                name={`social_links.${index}`}
                form={form}
                formDisabled={formDisabled}
                removeItem={() => removeLink(item)}
              />
            );
          })}

        <Button
          text={"Добавить ссылку"}
          containerStyle={{ marginTop: 8, marginBottom:64 }}
          onClick={addLink}
        />
      </div>
      <div style={styles.footer}>
        <div style={styles.footerWrapper}>
          <div style={styles.previewButton} onClick={goToPreview}>Превью</div>
        </div>
      </div>
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    mainBlockcontainerStyle: {
      marginBottom: 16,
    },
    textInputontainerStyle: {
      marginBottom: 16,
    },
    inputStyle: {
      minHeight: 160,
    },
    label: {
      ...Fonts.subhead1,
      color: Colors.neutral2,
      marginBottom: 8,
    },
    menuitem: {
      ...Fonts.headline1,
      color: Colors.color1,
      marginTop: 8,
      cursor: "pointer",
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 8,
    },
    previewButton: {
      ...Fonts.headline1,
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 48,
      borderRadius: 25,
      color: Colors.white,
      paddingLeft: 64,
      paddingRight: 64,
      marginRight: 16,
      cursor: "pointer",
    },
    addIcon: {
      color: Colors.white,
    },
    addButton: {
      backgroundColor: Colors.color1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 52,
      width: 52,
      borderRadius: 25,
      cursor: "pointer",
    },
    footer: {
      position: "fixed",
      right: 40,
      bottom: 32,
    },
  };
};
