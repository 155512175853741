import Joi from 'joi';
import saveCourse from '../methods/saveCourse';
import {Loader} from '../stores/Loader';
import {debounce} from '../utils';
import {getFormCollector} from './../stores/core/collector';
import EventEmitter from './../stores/core/emitter';
import {Storage} from './../stores/core/storage';
// import saveCourse from './../screens/CreateCourseStack/methods/saveCourse';
import {blockJoiPreSchema} from './base';

window.Joi = Joi;

const collector = getFormCollector(
  {
    _id: void 0,
    title: void 0,
    description: void 0,
    intro_blocks: [],
    category: void 0,
    lessons: [],
    introductory_lesson: [],
    final_lesson: [],
    status: 0,
    _uv: void 0,
    social_links: [],
    reject_comment: void 0,
    _start_edit: void 0,
    _options: void 0
  },
  Joi.object({
    _id: Joi.any(),
    title: Joi.string().allow(''),
    description: Joi.string().allow(''),
    intro_blocks: Joi.array(),
    category: Joi.string(),
    lessons: Joi.array(),
    introductory_lesson: Joi.array(),
    final_lesson: Joi.array(),
    _uv: Joi.any(),
    status: Joi.any(),
    social_links: Joi.array(),
    reject_comment: Joi.any(),
    _start_edit: Joi.any(),
    _options: Joi.object()

  }),
  (key, value, reset, force) => {
    Emitter.emit(key, value);

    if (!reset && key !== 'status') {
      dSave();
    }
  },
);

export const Emitter = new EventEmitter();
export default collector;

window.createCourse = collector;

export const validInfoStrict = () => {
  return collector.validate2(
    false,
    Joi.object({
      title: Joi.string().required(),
      description: Joi.string().required(),
      intro_blocks: Joi.array().items(blockJoiPreSchema()),
      category: Joi.string().required(),
      social_links: Joi.array().items(Joi.object({
        link: Joi.string().required(),
        title: Joi.string().required(),
      }))
    }),
    {allowUnknown: true, abortEarly: false},
  );
};

export const validInfo = () => {
  return collector.validate2(
    false,
    Joi.object({
      title: Joi.string().required(),
      description: Joi.string().required(),
      intro_blocks: Joi.array().items(blockJoiPreSchema()),
      category: Joi.string().required(),
      social_links: Joi.array().items(Joi.object({
        link: Joi.string().required(),
        title: Joi.string().required(),
      }))
    }),
    {allowUnknown: true, abortEarly: false},
  );
};

export const validateHometask = path => {
  return collector.validate2(
    collector.link(path),
    Joi.object({
      blocks: Joi.array().items(blockJoiPreSchema()),
    }),
    {
      allowUnknown: true,
      abortEarly: false,
    },
  );
};

export const validateLesson = path => {
  return collector.validate2(
    collector.link(path),
    Joi.object({
      hometasks: Joi.array().items(
        Joi.object({
          blocks: Joi.array().items(blockJoiPreSchema()),
        }),
      ),
      title: Joi.string().required(),
      blocks: Joi.array().items(blockJoiPreSchema()).required().min(1),
    }),
    {
      allowUnknown: true,
      abortEarly: false,
    },
  );
};

export const validLessons = () => {
  return collector.validate2(
   false,
    Joi.object({
      lessons: Joi.array()
        .items(
          Joi.object({
            hometasks: Joi.array().items(
              Joi.object({
                blocks: Joi.array().items(blockJoiPreSchema()),
              }),
            ),
            title: Joi.string().required(),
            blocks: Joi.array().items(blockJoiPreSchema()).required().min(1),
          }),
        )
        .required()
        .min(1),
      introductory_lesson: Joi.array()
        .items(
          Joi.object({
            title: Joi.string().required(),
            blocks: Joi.array().items(blockJoiPreSchema()).required().min(1),
          }),
        )
        .required()
        .min(1),
      final_lesson: Joi.array()
        .items(
          Joi.object({
            title: Joi.string().required(),
            blocks: Joi.array().items(blockJoiPreSchema()).required().min(1),
          }),
        )
        .required()
        .min(1),
    }),
    {allowUnknown: true, abortEarly: false},
  );
};

export const rehydrate = () => {
  const waitTaskCount = [];

  const finalLesson = collector.get('final_lesson');
  let finalLessonHasChanged = false;

  finalLesson.map(lesson => {
    lesson.blocks.forEach(block => {
      if (filterfile(block)) {
        if (block?.value?.ready !== true && block?.value?.tmpid) {
          const task = Loader.get(`tasks.${block.value.tmpid}`);
          if (task?.ready) {
            Loader.clear(`tasks.${block.tmpid}`);
            block.value = task;
            finalLessonHasChanged = true;
          } else {
            waitTaskCount.push({
              section: 'final_lesson',
              block: block,
              lesson,
            });
          }
        }
      }
    });
    lesson?.hometasks?.forEach(hometask => {
      hometask?.blocks?.forEach(block => {
        if (filterfile(block)) {
          if (block?.value?.ready !== true && block?.value?.tmpid) {
            const task = Loader.get(`tasks.${block.value.tmpid}`);
            if (task?.ready) {
              Loader.clear(`tasks.${block.tmpid}`);
              block.value = task;
              finalLessonHasChanged = true;
            } else {
              waitTaskCount.push({
                section: 'final_lesson_hometasks',
                block: block,
                lesson,
                hometask,
              });
            }
          }
        }
      });
    });
    return lesson;
  });

  finalLessonHasChanged && collector.set('final_lesson', finalLesson);
  ////////

  const introductoryLesson = collector.get('introductory_lesson');
  let introductoryLessonHasChanged = false;

  introductoryLesson.map(lesson => {
    lesson.blocks.forEach(block => {
      if (filterfile(block)) {
        if (block?.value?.ready !== true && block?.value?.tmpid) {
          const task = Loader.get(`tasks.${block.value.tmpid}`);
          if (task?.ready) {
            Loader.clear(`tasks.${block.tmpid}`);
            block.value = task;
            introductoryLessonHasChanged = true;
          } else {
            waitTaskCount.push({
              section: 'introductory_lesson',
              block: block,
              lesson,
            });
          }
        }
      }
    });
    lesson?.hometasks?.forEach(hometask => {
      hometask?.blocks?.forEach(block => {
        if (filterfile(block)) {
          if (block?.value?.ready !== true && block?.value?.tmpid) {
            const task = Loader.get(`tasks.${block.value.tmpid}`);
            if (task?.ready) {
              Loader.clear(`tasks.${block.tmpid}`);
              block.value = task;
              introductoryLessonHasChanged = true;
            } else {
              waitTaskCount.push({
                section: 'introductory_lesson_hometasks',
                block: block,
                lesson,
                hometask,
              });
            }
          }
        }
      });
    });
    return lesson;
  });

  introductoryLessonHasChanged &&
    collector.set('introductory_lesson', introductoryLesson);
  /////////

  const lessons = collector.get('lessons');
  let lessonsHasChanged = false;

  lessons.map(lesson => {
    lesson.blocks.forEach(block => {
      if (filterfile(block)) {
        if (block?.value?.ready !== true && block?.value?.tmpid) {
          const task = Loader.get(`tasks.${block.value.tmpid}`);
          if (task?.ready) {
            Loader.clear(`tasks.${block.tmpid}`);
            block.value = task;
            lessonsHasChanged = true;
          } else {
            waitTaskCount.push({
              section: 'lessons',
              block: block,
              lesson,
            });
          }
        }
      }
    });
    lesson?.hometasks?.forEach(hometask => {
      hometask?.blocks?.forEach(block => {
        if (filterfile(block)) {
          if (block?.value?.ready !== true && block?.value?.tmpid) {
            const task = Loader.get(`tasks.${block.value.tmpid}`);
            if (task?.ready) {
              Loader.clear(`tasks.${block.tmpid}`);
              block.value = task;
              lessonsHasChanged = true;
            } else {
              waitTaskCount.push({
                section: 'lessons_hometasks',
                block: block,
                lesson,
                hometask,
              });
            }
          }
        }
      });
    });
    return lesson;
  });

  lessonsHasChanged && collector.set('lessons', lessons);
  /////////
  const introBlocks = collector.get('intro_blocks');
  let introBlocksHasChanged = false;

  introBlocks.forEach(block => {
    if (filterfile(block)) {
      if (block?.value?.ready !== true && block?.value?.tmpid) {
        const task = Loader.get(`tasks.${block.value.tmpid}`);
        if (task?.ready) {
          Loader.clear(`tasks.${block.tmpid}`);
          block.value = task;
          introBlocksHasChanged = true;
        } else {
          waitTaskCount.push({
            section: 'intro_blocks',
            block: block,
          });
        }
      }
    }
  });

  introBlocksHasChanged && collector.set('intro_blocks', introBlocks);
  /////////

  return waitTaskCount;

  // Проходимся по всем таскам и обновляем форму
  // на случай если таски зарезолвисись после размонтирования компонента формы
};

window.rehydrate2 = rehydrate;

export const save = async () => {
  const data = collector.get();

  if (data._id) {
    console.log(data)
    await saveCourse({silent: true});
  } else {
    await Storage.setJSON('last_course', data);
  }
};

const dSave = debounce(save, 1000);

export const restore = async () => {
  const data = await Storage.getJSON('last_course');
  collector.reset(data);

  return data;
};

export const hasDraft = async () => {
  const data = await Storage.get('last_course');

  return !!data;
};

export const removeDraft = async () => {
  await Storage.remove('last_course');
  collector.reset();
};

export const clear = async () => {
  await Storage.remove('last_course');
  collector.reset();
};

function filterfile(b) {
  return ['photo', 'video'].includes(b.type);
}