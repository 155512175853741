import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../constants/theme";
import { ArrowLeft, X } from "react-feather";

import form, {
  validLessons,
  validInfo,
} from "./../../../forms/createNewCourse";
import { useEffect, useState } from "react";

export default function Menu() {
  const styles = useTheme(dynamicStyle);
  const navigate = useNavigate();
  const _id = form.get("_id", "new");

  const [valid, setValid] = useState({
    lessons: false,
    info: false,
  });

  useEffect(() => {
    const lessons = validLessons();
    const info = validInfo();

    setValid({
      lessons: lessons.error === null,
      info: info.error === null,
    });
  }, []);

  return (
    <>
      <div style={styles.title}>
        <ArrowLeft
          onClick={() => navigate(-1)}
          style={styles.backIcon}
          size={32}
        />
        {form.get("title", "Новый курс")}
      </div>
      <div>
        <div
          style={{...styles.menuitem,...(!valid.lessons ? styles.alert : {}) }}
          onClick={() => navigate(`/course/${_id}/lessons`)}
        >
          Уроки
          {!valid.lessons && (
            <div style={styles.iconWrapper}>
              <X style={styles.xicon} size={16} />
            </div>
          )}
        </div>
        <div
         style={{...styles.menuitem,...(!valid.info ? styles.alert : {}) }}
          onClick={() => navigate(`/course/${_id}/start_page`)}
        >
          Станица курса
          {!valid.info && (
            <div style={styles.iconWrapper}>
              <X style={styles.xicon} size={16} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    alert:{
      color: Colors.accent2,
    },
    menuitem: {
      ...Fonts.headline1,
      color: Colors.color1,
      marginTop: 8,
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
    },
    xicon: {
      color: Colors.white,
    },
    iconWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: Colors.accent2,
      borderRadius: 50,
      height: 20,
      width: 20,
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
  };
};
