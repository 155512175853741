import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qr-svg";
import { useNavigate } from "react-router-dom";
import { sendQRUUIDCode } from "../../api";
import Logo from "../../components/Logo";
import { useTheme } from "../../constants/theme";
import { Settings } from "../../stores/Settings";
import { UUIDGeneratorBrowser, wait } from "../../utils";

export default function AuthPage() {
  const styles = useTheme(dynamicStyle);
  const [qr, setQr] = useState(Settings.get("webuuid"));
  const navigate = useNavigate();
  const cancel = useRef(false);

  useEffect(() => {
    if (Settings.has("authorizationToken")) {
      navigate("/");
      return;
    }
    let uuid = Settings.get("webuuid");

    if (!uuid) {
      uuid = UUIDGeneratorBrowser("mr:qr:");
      Settings.set("webuuid", uuid);
    }

    setQr(uuid);

    waitAutorization(uuid);

    return () => {
      cancel.current = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const waitAutorization = async (uuid) => {
    if (cancel.current) return;
    try {
      const res = await sendQRUUIDCode(uuid);

      if (res.token === undefined) {
        await wait(5000);
        waitAutorization(uuid);
      } else {
        Settings.set("authorizationToken", res.token);
        if (res?.params?.mode && res?.params?.mode === "draft") {
          navigate(`/course/${res?.params?.crs_id}/menu`);
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      // alert(1)
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.qrWrapper}>
        <QRCode
          bgColor={styles.qrBgColor}
          fgColor={styles.fgColor}
          level="Q"
          style={styles.qr}
          value={qr}
        />
      </div>
      <div style={styles.title}>
        Отсканируйте код при помощи приложения Udeu
      </div>
      <div>
        <ul>
          <li style={styles.li}>Откройте приложение Udeu</li>
          <li style={styles.li}>Перейдите в профиль, раздел QR-код</li>
          <li style={styles.li}>Наведите телефон на этот экран</li>
        </ul>
      </div>

      <Logo containerStyle={styles.logoWrapper} />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    qr: {
      width: 256,
    },
    qrBgColor: Colors.backgroundLayout,
    fgColor: Colors.neutral1,
    logoWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "flex-end",
      position: "absolute",
      bottom: 24,
      right: 16,
    },
    li: {
      ...Fonts.headline1,
      color: Colors.neutral1,
      lineHeight: "32px",
    },
    title: {
      ...Fonts.title2,
      color: Colors.neutral1,
      margin: 24,
    },
    qrWrapper: {},
    container: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
    },
  };
};
