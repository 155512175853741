import { Settings } from "../stores/Settings";
import axios from 'axios';

export const fetchData = async (
    uri,
    body = {},
    token = false,
    method = 'POST',
  ) => {
    //await wait(1000);
    const url = `${Settings.get('server.proxy_host')}${uri}`;
    const headers = {
      'Content-Type': 'application/json',
      'Accept-Language': 'ru',
    };
  
    if (Settings.has('authorizationToken')) {
      headers.authorization = `Bearer ${Settings.get('authorizationToken')}`;
    } else if (token) {
      // console.log({url, method, token});
  
      const error = new Error();
  
      error.exception = 'PermissionDenied';
      error.details = {};
      error.message = 'You need to log in';
      error.name = 'Permission Denied';
      error.parsed = true;
  
      // console.log(uri);
      throw error;
    }
    // console.log(url, method, body);
  
    try {
      const res = await fetch(url, {
        method,
        body: JSON.stringify(body),
        headers,
      });
  
      if (res.ok) {
        const json = await res.json();
  
        //console.log(url, method, body, json);
  
        return json;
      } else {
        const e = await res.json();
       // console.log('!!!!', url, method, body, e);
  
        const error = new Error(e.http_error);
  
        //console.log(url, method, body, e);
  
        error.exception = e.exception;
        error.details = e.details;
        error.message = e.message;
        error.name = e.http_error;
        error.parsed = true;
  
        throw error;
      }
    } catch (e) {
      // console.log(url, method, body);
  
      // console.error(e);
      if (!e.parsed) {
        // eslint-disable-next-line no-ex-assign
        e = new Error();
        e.name = 'Service Unavailable';
        e.message = 'Service Temporarily Unavailable';
        e.code = 503;
        e.hidden = true;
        e.parsed = true;
      }
    
      throw e;
    }
  };

  export const validate = (schema, payload, options = {}) => {
    const {error, value} = schema.validate(payload, {
      ...options,
      allowUnknown: true,
    });
  
    if (!error) {
      return value;
    } else {
      const e = new Error();
  
      e.details = error.details;
      e.message = error.message;
      e.name = error.name;
      e.parsed = true;
  
      return e;
    }
  };

  export const errorHandler = function (error) {
    console.log('errorHandler', error)
  }

  export const VideoExtensions = {
    '3g2': 'video/3gpp2',
    '3gp': 'video/3gpp',
    aac: 'audio/x-aac',
    au: 'audio/basic',
    avi: 'video/x-msvideo',
    bmp: 'image/bmp',
    gif: 'image/gif',
    h261: 'video/h261',
    h263: 'video/h263',
    h264: 'video/h264',
    ice: 'x-conference/x-cooltalk',
    ico: 'image/x-icon',
    ief: 'image/ief',
    jpe: 'image/jpeg',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    jpgm: 'video/jpm',
    jpgv: 'video/jpeg',
    jpm: 'video/jpm',
    m1v: 'video/mpeg',
    m2a: 'audio/mpeg',
    m2v: 'video/mpeg',
    m3a: 'audio/mpeg',
    m3u: 'audio/x-mpegurl',
    m4u: 'video/vnd.mpegurl',
    m4v: 'video/x-m4v',
    mov: 'video/quicktime',
    movie: 'video/x-sgi-movie',
    mp2: 'audio/mpeg',
    mp2a: 'audio/mpeg',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    mp4a: 'audio/mp4',
    mp4v: 'video/mp4',
    mpa: 'video/mpeg',
    mpe: 'video/mpeg',
    mpeg: 'video/mpeg',
    mpg: 'video/mpeg',
    mpg4: 'video/mp4',
    mpga: 'audio/mpeg',
    oga: 'audio/ogg',
    ogg: 'audio/ogg',
    ogv: 'video/ogg',
    pic: 'image/x-pict',
    png: 'image/png',
    psd: 'image/vnd.adobe.photoshop',
    qt: 'video/quicktime',
    spx: 'audio/ogg',
    svg: 'image/svg+xml',
    svgz: 'image/svg+xml',
    viv: 'video/vnd.vivo',
    wav: 'audio/x-wav',
    wbmp: 'image/vnd.wap.wbmp',
    wm: 'video/x-ms-wm',
    wma: 'audio/x-ms-wma',
    wmv: 'video/x-ms-wmv',
  };

  export const uploadToGCS = async (url,data, contentType) => {
    const res = await axios.put(url, data, {
      headers: {
        'Content-Type': contentType
      }
    });

    return res;
  }
  
  export const uploadBinaryFile = ({
    upload_url,
    content_type,
    file,
    onProgress,
  }) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
  
      xhr.onload = () => {
        if (xhr.status === 200) {
          // succeeded
          resolve(xhr.response);
        } else {
          reject(xhr.response);
        }
      };
  
      xhr.onerror = error => {
        reject(error);
      };
  
      xhr.open('PUT', upload_url);
      xhr.setRequestHeader('content-type', content_type);
      xhr.upload.onprogress = function (event) {
        console.log('progress', event)
        if (onProgress) {
          onProgress(Math.round((event.loaded / event.total) * 100), event);
        }
      };
      xhr.send(file);
    });
  };

  export const createCourse = async payload => {
    const res = await fetchData('courses', payload, true);
  
    return res;
  };
  
  export const updateCourse = async payload => {
    const res = await fetchData('courses', payload, true, 'PUT');
  
    return res;
  };

  export const removeCourse = async payload => {
    const res = await fetchData('courses', payload, true, 'DELETE');
  
    return res;
  };


  export const getMe = async (body = {}) => {
    const res = await fetchData('clients/me', body, true);
  
    return res;
  };

  export const getMyCourses = async param => {
    const res = await fetchData('courses/org/search', param, true);
  
    return res;
  };
  

  export const getMyStudyGroup = async body => {
    const res = await fetchData('study_groups/org/search', body, true);
  
    return res;
  };
  

  export const createFile = async body => {
    const res = await fetchData('files', body, true);
  
    return res;
  };
  
  export const removeFile = async _id => {
    const res = await fetchData('files', {_id}, true, 'DELETE');
  
    return res;
  };

  export const fileIsReady = async _id => {
    const res = await fetchData('files/ready', {_id});
  
    return res;
  };
  
  export const getCreateCourseOptions = async function () {
    const res = await fetchData('courses/org/options', {}, true);
  
    return res;
  };

  export const getMyCourse = async _id => {
    const res = await fetchData('courses/org/entity', {_id}, true);
  
    return res;
  };

  export const sendQRUUIDCode = async uuid => {
    const res = await fetchData('clients/qr/auth', {uuid});
  
    return res;
  };

  export const deauth = async () => {
    const res = await fetchData('clients/deauth', {}, true);
  
    return res;
  };

  export const parseSoclialLink = async text => {
    const res = await fetchData('social_links/check', {text}, true);
  
    return res;
  };
  