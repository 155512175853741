import moment from "moment";
import { useEffect, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  CornerLeftDown,
  CornerRightDown,
} from "react-feather";
import Button from "../../../components/Button";
import { getClasses } from "../../../constants/theme";
import saveCourse from "../../../methods/saveCourse";
import { History } from "../../../stores/History";
import { Settings } from "../../../stores/Settings";
import courseForm from "./../../../forms/createNewCourse";

export default function CourseSavedComponent(props) {
  const classes = getClasses(dynamicStyle);
  const [time, setTime] = useState();
  const [canGo, setCanGo] = useState({
    prev: History.canPrev(props._id),
    next: History.canNext(props._id),
  });

  useEffect(() => {
    const off = Settings.on("course_saved", ({ next }) => {
      setTime(next);
    });

    const off2 = History.on(`course.${props._id}.index`, ({ next, prev }) => {
      if (next !== prev) {
        setCanGo({
          prev: History.canPrev(props._id),
          next: History.canNext(props._id),
        });
      }
    });

    return () => {
      off();
      off2();
    };
  }, []);

  const prev = () => {
    const { data } = History.getPrev(props._id);

    data && courseForm.reset(data);
  };

  const next = () => {
    const { data } = History.getNext(props._id);

    data && courseForm.reset(data);
  };

  const save = () => {
    saveCourse({ silent: true });
  };

  return (
    <div className={classes("scontainer")}>
      {time && (
        <div className={classes("container")}>
          Данные сохранены {moment(time).format("DD MMMM HH:mm:ss")}
        </div>
      )}
      {canGo.next && (
        <Button
          onClick={save}
          containerStyle={{
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: 14,
          }}
          text={"Сохранить"}
        />
      )}
      {canGo.prev && (
        <ChevronLeft className={classes("icon")} onClick={prev} size={16} />
      )}
      {canGo.next && (
        <ChevronRight className={classes("icon")} onClick={next} size={16} />
      )}
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    icon: {
      cursor: "pointer",
    },
    scontainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    container: {
      ...Fonts.footnote,
      paddingLeft: 16,
      color: Colors.neutral2,
    },
  };
};
