import { deauth, errorHandler } from "../api";
import { Settings } from "../stores/Settings";

export default async function Deauth (navigate){
    try {
        await deauth();
        Settings.clear('me');
        Settings.clear('authorizationToken');
        navigate && navigate('/')

    } catch (err){
        errorHandler(err);
    }
}