import { getMyStudyGroup } from "../../../api";
import InfinityList from "../../../components/InfinityList";
import StudyGroupItem from "../components/StudyGroupItem";

export default function ArchiveList(){
    const onClick = () => {};

    return (
      <InfinityList
        size={100}
        renderItem={(item) => <StudyGroupItem onClick={onClick} item={item} />}
        fetchData={(param) => getMyStudyGroup({ ...param, mode: "finished" })}
      />
    );
}