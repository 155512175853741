import { ArrowLeft } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import BlockView from "../../../components/BlockView/BlockView";
import { getClasses } from "../../../constants/theme";
import courseForm from "./../../../forms/createNewCourse";
export default function LessonPreview(props) {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();
  const { id, index, type } = useParams();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className={classes("title")}>
        <ArrowLeft
          onClick={() => goBack()}
          className={classes("backIcon")}
          size={32}
        />
        {courseForm.get(`${type}.${index}.title`)}
      </div>
      <BlockView blocks={courseForm.get(`${type}.${index}.blocks`)} />
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 8,
    },
  };
};
