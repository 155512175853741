import React, { useEffect } from "react";
import {
  BrowserRouter,
  Outlet,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

import AuthPage from "./../pages/AuthPage/AuthPage";
import CoursesListPage from "./../pages/CoursesListPage/CoursesListPage";
import DraftList from "./../pages/CoursesListPage/SubPages/DraftList";
import ArchiveList from "./../pages/CoursesListPage/SubPages/ArchiveList";
import ForModerationList from "./../pages/CoursesListPage/SubPages/ForModerationList";
import StudyGriupList from "./../pages/CoursesListPage/SubPages/StudyGriupList";
import TemplateList from "./../pages/CoursesListPage/SubPages/TemplateList";
import { errorHandler, getMe } from "../api";
import CreateNewCourse from "../pages/CreateNewCourse/CreateNewCourse";
import Menu from "../pages/CreateNewCourse/SubPage/Menu";
import StartPage from "../pages/CreateNewCourse/SubPage/StartPage";
import Lessons from "../pages/CreateNewCourse/SubPage/Lessons";
import { Settings } from "../stores/Settings";
import AddLesson from "../pages/CreateNewCourse/SubPage/AddLesson";
import AddHometask from "../pages/CreateNewCourse/SubPage/AddHometask";
import LessonPreview from "../pages/CreateNewCourse/SubPage/LessonPreview";
import HometaskPreview from "../pages/CreateNewCourse/SubPage/HometaskPreview";
import StartPagePreview from "../pages/CreateNewCourse/SubPage/StartPagePreview";

export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/list" element={<CoursesListPage />}>
            <Route path="/list/draft" element={<DraftList />} />
            <Route path="/list/archive" element={<ArchiveList />} />
            <Route
              path="/list/for_moderation"
              element={<ForModerationList />}
            />
            <Route path="/list/study_group" element={<StudyGriupList />} />
            <Route path="/list/template" element={<TemplateList />} />
          </Route>
          <Route path="/course/:id" element={<CreateNewCourse />}>
            <Route path="/course/:id/menu" element={<Menu />} />
            <Route path="/course/:id/lessons" element={<Lessons />} />
            <Route
              path="/course/:id/lessons/:type/:index"
              element={<AddLesson />}
            />
            <Route
              path="/course/:id/lessons/:type/:index/preview"
              element={<LessonPreview />}
            />
            <Route
              path="/course/:id/lessons/:type/:index/hometask/:hindex"
              element={<AddHometask />}
            />
            <Route
              path="/course/:id/lessons/:type/:index/hometask/:hindex/preview"
              element={<HometaskPreview />}
            />
            <Route path="/course/:id/start_page" element={<StartPage />} />
            <Route path="/course/:id/start_page/preview" element={<StartPagePreview />} />

          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (Settings.has("authorizationToken")) {
      getMe()
        .then((res) => Settings.memorize("me", res))
        .then(() => {
          if (location.pathname === "/") {
            navigate("/list");
          }
        })
        .catch((err) => {
          if (err.exception === "SessionExpiredError") {
            Settings.clear("me");
            Settings.clear("authorizationToken");
          }
          navigate("/auth");
          errorHandler(err);
        });
    } else {
      navigate("/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === "/"]);

  return <Outlet />;
}
