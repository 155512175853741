import { getMyCourses } from "../../../api";
import InfinityList from "../../../components/InfinityList";
import ForModerationItem from "../components/ForModerationItem";

export default function ForModerationList(){
    const onClick = () => {}
    return (
      <InfinityList
        renderItem={(item) => <ForModerationItem onClick={onClick} item={item} />}
        fetchData={(param) => getMyCourses({ ...param, mode: "pending_consideration" })}
      />
    );
}