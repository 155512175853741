import moment from "moment";
import { Settings } from "../stores/Settings";

export const get = (obj) => {
  return (path, defaultValue) => {
    if (path === undefined) return obj;

    if (typeof path === "string") path = path.split(".");

    let node = obj;

    for (let i = 0; i < path.length; i++) {
      const key = path[i];

      if (!node) {
        node = undefined;

        break;
      }

      node = node[key];
    }

    return typeof node === "undefined" ? defaultValue : node;
  };
};

export const set = (obj) => {
  return (path, value) => {
    if (typeof path === "string") path = path.split(".");

    let node = obj;

    for (var i = 0; i < path.length; i++) {
      const key = path[i];
      const nextKey = path[i + 1];

      if (!node) {
        node = isNaN(nextKey) ? {} : [];
      }

      node = node[key];
    }

    node[path[i]] = value;

    return obj;
  };
};

export const getUID = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

export const UUIDGeneratorBrowser = (prefix) =>
  prefix +
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

export const wait = async (time) => {
  return new Promise((resolve) => setTimeout(() => resolve(), time));
};

export const debounce = (func, wait, immediate) => {
  let timeoutId;

  return function (...args) {
    const later = () => {
      timeoutId = null;
      if (!immediate) func(...args);
    };
    const callNow = immediate && !timeoutId;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(later, wait);
    if (callNow) func(...args);
  };
};

export const pureSet = (obj, path, defaultValue, setter) => {
  if (typeof path === "string") path = path.split(".");

  if (typeof setter === "undefined") setter = defaultValue;

  let value = get(obj, path);

  if (
    (typeof value === "undefined" ||
      (typeof value === "number" && isNaN(value))) &&
    typeof defaultValue !== "function"
  ) {
    value = defaultValue;
  }

  let node = obj;

  for (var i = 0; i < path.length - 1; i++) {
    const key = path[i];
    const nextKey = +path[i + 1];

    if (!node[key]) node[key] = isNaN(nextKey) ? {} : [];

    node = node[key];
  }

  node[path[i]] = typeof setter === "function" ? setter(value) : setter;

  return obj;
};

export const courseSerialize = (course) => {
  course._start_edit = void 0;

  course.lessons.forEach((lesson) => {
    lesson.id = void 0;

    (lesson.hometasks || []).forEach((ht) => {
      (ht.blocks || []).forEach((block) => {
        block.id = void 0;

        if (blockIsFile(block)) {
          block.value = block.value._id;
        }
      });
    });

    lesson.blocks.forEach((block) => {
      block.id = void 0;

      if (blockIsFile(block)) {
        block.value = block.value._id;
      }
    });
  });

  course.final_lesson.forEach((lesson) => {
    lesson.id = void 0;

    (lesson.hometasks || []).forEach((ht) => {
      (ht.blocks || []).forEach((block) => {
        block.id = void 0;

        if (blockIsFile(block)) {
          block.value = block.value._id;
        }
      });
    });

    lesson.blocks.forEach((block) => {
      block.id = void 0;

      if (blockIsFile(block)) {
        block.value = block.value._id;
      }
    });
  });

  course.introductory_lesson.forEach((lesson) => {
    lesson.id = void 0;

    (lesson.hometasks || []).forEach((ht) => {
      (ht.blocks || []).forEach((block) => {
        block.id = void 0;

        if (blockIsFile(block)) {
          block.value = block.value._id;
        }
      });
    });

    lesson.blocks.forEach((block) => {
      block.id = void 0;
      if (blockIsFile(block)) {
        block.value = block.value._id;
      }
    });
  });

  course.intro_blocks.forEach((block) => {
    block.id = void 0;

    if (blockIsFile(block)) {
      block.value = block.value._id;
    }
  });

  course?.social_links &&
    course.social_links.forEach((link) => {
      link.id = void 0;
      link.loading = void 0;
    });

  delete course._uv;
  delete course.status;
  delete course.reject_comment;

  return course;
};

export const blockIsFile = (b) => {
  return ["photo", "video", "audio", "builtInPDF"].includes(b.type);
};

export const isAllBlockReady = (blocks = []) => {
  return blocks.every((b) => b.value.ready);
};

export const impoverishBlocks = (blocks) => {
  blocks.forEach((block) => {
    delete block.id;

    if (blockIsFile(block)) {
      block.value = block.value._id;
    }
  });

  return blocks;
};

const enrichBlock = (block) => {
  if (block.value === undefined) {
    block.value = {};
    return;
  }
  block.value.blcok_id = block.id;
  block.value.tmpid = getUID();
  block.value.ready = true;
  block.value.loaded = true;
  block.value.progress = 100;
};

export const enrichAnyBlock = (block) => {
  block.id = getUID();

  if (blockIsFile(block)) {
    enrichBlock(block);
  }
};

export const enrichCourse = (course) => {
  // course.paid = status.isPaid();
  // course.group_chat = status.isHasGroupChat();
  // course.chat_with_organizer = status.isHasChatWithOrg();
  // course.course_price = course.course_price + '';

  course.intro_blocks.forEach((block) => {
    block.id = getUID();

    if (blockIsFile(block)) {
      enrichBlock(block);
    }
  });

  course.lessons.forEach((lesson) => {
    lesson.id = getUID();

    (lesson.hometasks || []).forEach((ht) => {
      (ht.blocks || []).forEach((block) => {
        block.id = getUID();

        if (blockIsFile(block)) {
          enrichBlock(block);
        }
      });
    });

    (lesson.blocks || []).forEach((block) => {
      block.id = getUID();

      if (blockIsFile(block)) {
        enrichBlock(block);
      }
    });
  });

  course.final_lesson.forEach((lesson) => {
    lesson.id = getUID();

    (lesson.hometasks || []).forEach((ht) => {
      (ht.blocks || []).forEach((block) => {
        block.id = getUID();

        if (blockIsFile(block)) {
          enrichBlock(block);
        }
      });
    });

    (lesson.blocks || []).forEach((block) => {
      block.id = getUID();

      if (blockIsFile(block)) {
        enrichBlock(block);
      }
    });
  });

  course.introductory_lesson.forEach((lesson) => {
    lesson.id = getUID();

    (lesson.hometasks || []).forEach((ht) => {
      (ht.blocks || []).forEach((block) => {
        block.id = getUID();

        if (blockIsFile(block)) {
          enrichBlock(block);
        }
      });
    });

    (lesson.blocks || []).forEach((block) => {
      block.id = getUID();

      if (blockIsFile(block)) {
        enrichBlock(block);
      }
    });
  });

  course?.social_links &&
    course.social_links.forEach((item) => {
      item.id = getUID();
      item.loading = false;
    });

  course._start_edit = true;

  return course;
};

export const studyGroupsSerialize = (payload) => {
  payload.intro_blocks.forEach((block) => {
    block.id = void 0;

    if (blockIsFile(block)) {
      block.value = block.value._id;
    }
  });

  payload.lessons.forEach((lesson) => {
    lesson.date = moment(lesson.date).format();
    lesson.has_hometask = void 0;
  });

  payload.course_start_date = moment(payload.course_start_date).format();
  payload.course_start_time = moment(payload.course_start_time).format();

  payload.course_price.amount = +payload.course_price.amount;

  if (
    payload.author_img_id &&
    payload.author_img_id[0] &&
    payload.author_img_id[0]._id
  ) {
    payload.author_img_id[0] = payload.author_img_id[0]._id;
  }

  payload?.social_links &&
    payload.social_links.forEach((link) => {
      link.id = void 0;
      link.loading = void 0;
    });

  delete payload._uv;
  delete payload.status;

  // TODO обработка полей в зависимоти от типа потока групповоой или индивидуальный

  if (payload.course_type === "group") {
    payload.register_before = void 0;
    payload.start_after_registration = void 0;
  } else {
    // индивидуальный
    payload.group_chat = false;
    payload.register_before = moment(payload.register_before)
      .set({
        second: "00",
      })
      .format();

    if (payload.start_after_registration) {
      payload.course_start_time = void 0;
      payload.course_start_date = void 0;
    }
  }

  return payload;
};

export const getTheme = () => "light";

export const makeImageUrl = (
  file,
  index = 0,
  size = "medium_filename",
  size2 = "small_filename",
  size3 = "url"
) => {
  if (!file) return;
  const cdn = Settings.get("server.cdn");

  if (typeof file === "string") {
    if (/^file|^http|^content/.test(file)) return file;
    else return `${cdn}${file}`;
  }

  file[index] && (file = file[index]);

  let url;

  url = file[size];

  if (!url) {
    url = file[size2];
  }

  if (!url) {
    url = file[size3] ? file[size3] : file.filename;
  }

  if (!url) return;

  if (/^http/.test(url)) return url;
  if (/^file/.test(url)) return url;
  if (/^content/.test(url)) return url;

  const bucket = file?.bucket ? file.bucket : Settings.get("server.bucket");

  return bucket ? `${cdn}${bucket}/${url}` : `${cdn}${url}`;
};

export const mmss = (ms) => {
  if (ms > 3600000) {
    return new Date(ms).toISOString().substr(11, 8);
  } else {
    return new Date(ms).toISOString().substr(14, 5);
  }
};

export const findError = (valid, p) => {
  if (!valid.error) {
    return;
  }
  return valid.error.details.some((item) => {
    return item.path.join(".").indexOf(p) !== -1;
  });
};

export const findError2 = (valid, test) => {
  if (!valid.error) {
    return;
  }
  const res = valid.error.details.find((item) => {
    return test.test(`${item.path.join(".")}`);
  });

  return res;
};

export const noop = async () => {};
