/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "../../constants/theme";
import { makeImageUrl } from "../../utils";
import { Loader } from "../../stores/Loader";
import { RefreshCw, X, Check } from "react-feather";
import { errorHandler, removeFile } from "../../api";
import { Settings } from "../../stores/Settings";

export const PhotoLodaer = function (props) {
  const styles = useTheme(props.styles ? props.styles : dynamicStyle);
  const [image, setImage] = useState(props.image);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(props?.image?._id);
  const [error, setError] = useState(void 0);
  const fFile = useRef();

  useEffect(() => {
    //props.autoOpen && image.tmpid === undefined && onepImagePicker();
  }, []);

  useEffect(() => {
    if (!image?.tmpid) return;
    const off = Loader.get(
      `tasks.${image.tmpid}.progress:${props.blockId}`,
      void 0,
      ({ next }) => {
        next !== undefined && setProgress(next);
      }
    );

    const off2 = Loader.get(
      `tasks.${image.tmpid}.error:${props.blockId}`,
      void 0,
      ({ next }) => {
        next !== undefined && setError(next);
      }
    );

    return () => {
      //Loader.removeWherReload(image.tmpid);
      off();
      off2();
    };
  }, [image?.tmpid, props.blockId]);

  useEffect(() => {
    if (!image?.tmpid) return;

    return Loader.get(
      `tasks.${image?.tmpid}.ready:${props.blockId}`,
      image,
      ({ next, prev, def }) => {
        if (next === true) {
          const timg = Loader.get(`tasks.${image?.tmpid}`);
          setLoaded(true);
          setImage(timg);
          props.onComplete(timg);
          Loader.remove(`tasks.${image?.tmpid}`);
        }
      }
    );
  }, [image, image?.tmpid, props.blockId, props]);

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (image && image._id !== undefined && props.removeWhewChange) {
      removeFile(image._id).catch((e) => errorHandler(e));
    }

    const MaxFileSize = Settings.get("me.subscription.max_file_size");

    if (file.size > Settings.get("me.subscription.max_file_size")) {
      Settings.emit("local_notification", {
        level: "error",
        text: "Превышен максимальный размер файла",
        subtext: `Максимальный размер файла ${(
          MaxFileSize /
          1024 /
          1024
        ).toFixed(2)}Мб`,
      });
      return props.onItemRemoved(image, props.blockId);
    }

    setLoaded(false);
    setProgress(0);

    const tmpfile = Loader.load(file, {
      blockId: props.blockId,
      prefix: props.prefix,
    });

   props.onComplete(tmpfile);

    setImage({
      local_url: URL.createObjectURL(file),
      tmpid: tmpfile.tmpid,
      file: file,
    });
  };

  const reloadFile = async () => {
    const file = image.file;
    setLoaded(false);
    setProgress(0);

    await Loader.removeWherReload(image.tmpid);
    const tmpfile = Loader.load(file, {
      blockId: props.blockId,
      prefix: props.prefix,
    });

    props.onComplete(tmpfile);

    setImage({
      url: file.path,
      tmpid: tmpfile.tmpid,
      file: file,
    });
    //Удаляем файл
    //Удаляем задачу
    //создаем новую с тем же uuid
  };

  const onOpenFileBrowser = () => {
    fFile.current.click();
  };
  return (
    <div>
      {Object.keys(image).length > 0 ? (
        <div
        onClick={onOpenFileBrowser}
          disabled={!props.reloadable}
          style={{
            ...styles.imageContainer,
            ...(error ? styles.errorContainer : {}),
          }}
        >
          <img
            style={styles.image}
            src={image.local_url ? image.local_url : makeImageUrl(image, void 0, "temp_url", "filename")}
          />

          {!loaded && props.disabled !== true && error === undefined && (
            <div style={styles.progressWrapper}>
              <div style={styles.progressText}>{progress} %</div>
            </div>
          )}
          {loaded && error === undefined && (
            <div style={styles.readyWrapper}>
              <Check style={styles.readyIcon} />
            </div>
          )}
          {props.onItemRemoved && (
            <div
            onClick={() => {
                props.onItemRemoved &&
                  props.onItemRemoved(image, props.blockId);
              }}
              style={styles.removeWrapper}
            >
              <X style={styles.removeIcon} />
            </div>
          )}

          {error && (
            <div onClick={reloadFile} style={styles.reloadWrapper}>
              <RefreshCw style={styles.reloadIcon} />
            </div>
          )}
        </div>
      ) : (
        <div disabled={props.disabled} onClick={onOpenFileBrowser}>
          {props.Component}
        </div>
      )}
      <input
        ref={fFile}
        accept="image/jpeg,image/png"
        style={styles.fileInput}
        type="file"
        onChange={onFileChange}
      />
    </div>
  );
};

PhotoLodaer.defaultProps = {
  image: void 0,
  removeWhewChange: false,
  autoOpen: false,
  reloadable: false,
};

const dynamicStyle = (Colors, Fonts) => {
  return {
    fileInput: {
      display: "none",
    },
    reloadIcon: {},
    reloadWrapper: {
      backgroundColor: Colors.accent2,
      position: "absolute",
    },
    errorContainer: {
      borderWidth: 1,
      borderColor: "#f00",
    },
    progressWrapper: {
      width: 48,
      height: 48,
      borderRadius: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: Colors.modal,
    },

    progressText: {
      marginTop: 2,
      ...Fonts.footnote,
      color: Colors.absoluteWhite,
    },

    readyIcon: {
      color: Colors.absoluteWhite,
      fontSize: 14,
    },
    readyWrapper: {
      backgroundColor: Colors.modal,
      width: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderBottomRightRadius: 4,
      position: "absolute",
      left: 0,
      top: 0,
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      overflow: "hidden",
      position: "relative",
    },

    refreshWrapper: {
      backgroundColor: Colors.modal,
      width: 50,
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: 16,
      position: "absolute",
      left: 0,
      bottom: 0,
    },
    refreshIcon: {
      color: Colors.absoluteWhite,
      fontSize: 22,
    },
    image: {
      height: 200,
      width: "100%",
      flex: 1,
      borderRadius: 10,
      objectFit: "cover",
    },
  };
};
