import {Store} from './core/store';

export const Settings = new Store({
  key: 'store:settings-2',
  defaultSchema: {
    theme: 'system',
    system_theme: 'system',
    authorized: false, // приложение загружено и авторизировано
    'use-system-locale': true,
    server: {
      host: 'https://api-getback.com',
      proxy_host: 'https://ev-proxy.getbackdev.com/v2/',
      cdn: 'https://storage.googleapis.com/',
      cdn_type: 'gcs',
      bucket: 'marathon-temp',
    },
  },
});

window.Settings = Settings;
