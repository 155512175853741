import { getMyCourses } from "../../../api";
import InfinityList from "../../../components/InfinityList";
import TemplateItem from "../components/TemplateItem";

export default function TemplateList(){
    const onClick = () => {}
    return (
      <InfinityList
        renderItem={(item) => <TemplateItem onClick={onClick} item={item} />}
        fetchData={(param) => getMyCourses({ ...param, mode: "confirmed" })}
      />
    );
}