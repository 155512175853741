class Status {
  constructor(value) {
    if (value instanceof Status) this._value = value._value;
    else this._value = value;
    if (typeof this._value === "string")
      this._value = this.constructor.mask(this._value);
  }
  isArchived() {
    return (this._value & 1) !== 0;
  }
  set(bitName) {
    const i = this.constructor.constList.indexOf(bitName);
    if (i === -1) throw new Error("Invalid status name");
    typeof this._value === "undefined" && (this._value = 0);
    this._value = this._value | (1 << i);
    return this;
  }
  unset(bitName) {
    const i = this.constructor.constList.indexOf(bitName);
    if (i === -1) throw new Error("Invalid status name");
    if (typeof this._value === "undefined") return this;
    this._value = this._value & ~(1 << i);
    return this;
  }
  get value() {
    return this._value;
  }
  toJSON() {
    return this._value;
  }
  toString() {
    const result = [];
    (this.constructor.constList || []).forEach((_const) => {
      if (this[`is${_const}`]()) result.push(_const);
    });
    return result.join("|");
  }
  any(b) {
    var bits = b;
    if (typeof this._value === "undefined" || typeof bits === "undefined")
      return false;
    if (typeof bits === "string") bits = bits.split("|");
    if (Array.isArray(bits)) {
      bits = bits
        .map((bit) => +this.constructor[bit])
        .reduce((prev, curr) => prev | curr);
    }
    return (this._value & bits) !== 0;
  }
  all(b) {
    var bits = b;
    if (typeof this._value === "undefined" || typeof bits === "undefined")
      return false;
    if (typeof bits === "string") bits = bits.split("|");
    if (Array.isArray(bits))
      bits = bits
        .map((bit) => +this.constructor[bit])
        .reduce((prev, curr) => prev | curr);
    return (this._value & bits) === bits;
  }
  static mask(b = [], negate = false) {
    let bits = b;
    if (typeof bits === "string") bits = bits.split("|");
    let result = 0;
    bits.forEach((bit) => {
      result |= +this[bit];
    });
    if (!negate) return result;
    else {
      return (
        (this.constList || [])
          .map((bit) => +this[bit])
          .reduce((prev, curr) => prev | curr) - result
      );
    }
  }
}
class ACTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
ACTStatus.Archived = 1;
ACTStatus.Removed = 2;
ACTStatus.Read = 4;
ACTStatus.constList = ["Archived", "Removed", "Read"];
ACTStatus.prefix = "ACT";
window["ACTStatus"] = ACTStatus;
class BUSStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
BUSStatus.Archived = 1;
BUSStatus.Removed = 2;
BUSStatus.constList = ["Archived", "Removed"];
BUSStatus.prefix = "BUS";
window["BUSStatus"] = BUSStatus;
class CHTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isMultiply(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isWithoutServiceMessages(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isWithoutIncTotals(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isTechnicalSupport(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isMainTargetChat(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
}
CHTStatus.Archived = 1;
CHTStatus.Removed = 2;
CHTStatus.Multiply = 4;
CHTStatus.Closed = 8;
CHTStatus.WithoutServiceMessages = 16;
CHTStatus.WithoutIncTotals = 32;
CHTStatus.TechnicalSupport = 64;
CHTStatus.MainTargetChat = 128;
CHTStatus.constList = [
  "Archived",
  "Removed",
  "Multiply",
  "Closed",
  "WithoutServiceMessages",
  "WithoutIncTotals",
  "TechnicalSupport",
  "MainTargetChat",
];
CHTStatus.prefix = "CHT";
window["CHTStatus"] = CHTStatus;
class CHMStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isChatOwner(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isChatMuted(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isMultiply(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isOrganizer(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isWithoutServiceMessages(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isBlocked(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isMyMessagesRead(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
}
CHMStatus.Archived = 1;
CHMStatus.Removed = 2;
CHMStatus.ChatOwner = 4;
CHMStatus.ChatMuted = 8;
CHMStatus.Closed = 16;
CHMStatus.Multiply = 32;
CHMStatus.Organizer = 64;
CHMStatus.WithoutServiceMessages = 128;
CHMStatus.Blocked = 256;
CHMStatus.MyMessagesRead = 512;
CHMStatus.constList = [
  "Archived",
  "Removed",
  "ChatOwner",
  "ChatMuted",
  "Closed",
  "Multiply",
  "Organizer",
  "WithoutServiceMessages",
  "Blocked",
  "MyMessagesRead",
];
CHMStatus.prefix = "CHM";
window["CHMStatus"] = CHMStatus;
class CLStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isAuthenticated(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isOnline(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isConfirmedSMS(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isRegistrationCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isDeveloper(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isFake(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isBlocked(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isTechnicalSupport(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isOrganizer(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isCommonChatsDenied(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isVerified(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
}
CLStatus.Archived = 1;
CLStatus.Removed = 2;
CLStatus.Authenticated = 4;
CLStatus.Online = 8;
CLStatus.ConfirmedSMS = 16;
CLStatus.RegistrationCompleted = 32;
CLStatus.Developer = 64;
CLStatus.Fake = 128;
CLStatus.Blocked = 256;
CLStatus.TechnicalSupport = 512;
CLStatus.Organizer = 1024;
CLStatus.CommonChatsDenied = 2048;
CLStatus.Verified = 4096;
CLStatus.constList = [
  "Archived",
  "Removed",
  "Authenticated",
  "Online",
  "ConfirmedSMS",
  "RegistrationCompleted",
  "Developer",
  "Fake",
  "Blocked",
  "TechnicalSupport",
  "Organizer",
  "CommonChatsDenied",
  "Verified",
];
CLStatus.prefix = "CL";
window["CLStatus"] = CLStatus;
class CRSStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isUnderConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isPendingConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isHidden(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
CRSStatus.Archived = 1;
CRSStatus.Removed = 2;
CRSStatus.Confirmed = 4;
CRSStatus.Rejected = 8;
CRSStatus.UnderConsideration = 16;
CRSStatus.PendingConsideration = 32;
CRSStatus.Hidden = 64;
CRSStatus.constList = [
  "Archived",
  "Removed",
  "Confirmed",
  "Rejected",
  "UnderConsideration",
  "PendingConsideration",
  "Hidden",
];
CRSStatus.prefix = "CRS";
window["CRSStatus"] = CRSStatus;
class FILEStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isDirectory(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isEmpty(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isProcessing(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isPreviewLoaded(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isHidden(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
}
FILEStatus.Archived = 1;
FILEStatus.Removed = 2;
FILEStatus.Directory = 4;
FILEStatus.Empty = 8;
FILEStatus.Processing = 16;
FILEStatus.Finished = 32;
FILEStatus.PreviewLoaded = 64;
FILEStatus.Hidden = 128;
FILEStatus.constList = [
  "Archived",
  "Removed",
  "Directory",
  "Empty",
  "Processing",
  "Finished",
  "PreviewLoaded",
  "Hidden",
];
FILEStatus.prefix = "FILE";
window["FILEStatus"] = FILEStatus;
class I18NStatus extends Status {
  isDirectory(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isMarkdown(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
I18NStatus.Archived = 1;
I18NStatus.Directory = 2;
I18NStatus.Markdown = 4;
I18NStatus.Removed = 8;
I18NStatus.constList = ["Archived", "Directory", "Markdown", "Removed"];
I18NStatus.prefix = "I18N";
window["I18NStatus"] = I18NStatus;
class KYCStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isVerified(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isWaitingVerification(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isExpired(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isInBlackList(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
KYCStatus.Archived = 1;
KYCStatus.Removed = 2;
KYCStatus.Verified = 4;
KYCStatus.Rejected = 8;
KYCStatus.WaitingVerification = 16;
KYCStatus.Expired = 32;
KYCStatus.InBlackList = 64;
KYCStatus.constList = [
  "Archived",
  "Removed",
  "Verified",
  "Rejected",
  "WaitingVerification",
  "Expired",
  "InBlackList",
];
KYCStatus.prefix = "KYC";
window["KYCStatus"] = KYCStatus;
class MBStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isBanned(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isPayStarted(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isPayCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isActive(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isHidden(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isHasReview(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isHasReviewAnswer(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
}
MBStatus.Archived = 1;
MBStatus.Removed = 2;
MBStatus.Banned = 4;
MBStatus.PayStarted = 8;
MBStatus.PayCompleted = 16;
MBStatus.Active = 32;
MBStatus.Hidden = 64;
MBStatus.HasReview = 128;
MBStatus.HasReviewAnswer = 256;
MBStatus.constList = [
  "Archived",
  "Removed",
  "Banned",
  "PayStarted",
  "PayCompleted",
  "Active",
  "Hidden",
  "HasReview",
  "HasReviewAnswer",
];
MBStatus.prefix = "MB";
window["MBStatus"] = MBStatus;
class MSGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isSystem(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isMuted(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
}
MSGStatus.Archived = 1;
MSGStatus.Removed = 2;
MSGStatus.Read = 4;
MSGStatus.System = 8;
MSGStatus.Muted = 16;
MSGStatus.constList = ["Archived", "Removed", "Read", "System", "Muted"];
MSGStatus.prefix = "MSG";
window["MSGStatus"] = MSGStatus;
class NFStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
NFStatus.Archived = 1;
NFStatus.Removed = 2;
NFStatus.constList = ["Archived", "Removed"];
NFStatus.prefix = "NF";
window["NFStatus"] = NFStatus;
class ODINStatus extends Status {}
ODINStatus.Archived = 1;
ODINStatus.constList = ["Archived"];
ODINStatus.prefix = "ODIN";
window["ODINStatus"] = ODINStatus;
class PLCStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
PLCStatus.Archived = 1;
PLCStatus.Removed = 2;
PLCStatus.constList = ["Archived", "Removed"];
PLCStatus.prefix = "PLC";
window["PLCStatus"] = PLCStatus;
class PREStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
PREStatus.Archived = 1;
PREStatus.Removed = 2;
PREStatus.constList = ["Archived", "Removed"];
PREStatus.prefix = "PRE";
window["PREStatus"] = PREStatus;
class SCDStatus extends Status {
  isPeriodic(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isKilled(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isPending(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
SCDStatus.Archived = 1;
SCDStatus.Periodic = 2;
SCDStatus.Finished = 4;
SCDStatus.Killed = 8;
SCDStatus.Pending = 16;
SCDStatus.Removed = 32;
SCDStatus.constList = [
  "Archived",
  "Periodic",
  "Finished",
  "Killed",
  "Pending",
  "Removed",
];
SCDStatus.prefix = "SCD";
window["SCDStatus"] = SCDStatus;
class SCHStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
SCHStatus.Archived = 1;
SCHStatus.Removed = 2;
SCHStatus.constList = ["Archived", "Removed"];
SCHStatus.prefix = "SCH";
window["SCHStatus"] = SCHStatus;
class SSNStatus extends Status {
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isConnected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
SSNStatus.Archived = 1;
SSNStatus.Closed = 2;
SSNStatus.Removed = 4;
SSNStatus.Connected = 8;
SSNStatus.constList = ["Archived", "Closed", "Removed", "Connected"];
SSNStatus.prefix = "SSN";
window["SSNStatus"] = SSNStatus;
class SGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isStarted(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isGroup(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isPaid(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isHasGroupChat(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isHasChatWithOrg(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isHasChatBetweenMembers(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isHasRating(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isAvailableImmediately(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isMoneyWithdrawn(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isStartAfterRegistration(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
  isDisplayReviews(v) {
    v === undefined && (v = this._value);
    return (v & 8192) !== 0;
  }
  isMoneyWithdrawStarted(v) {
    v === undefined && (v = this._value);
    return (v & 16384) !== 0;
  }
  isArchivedForFront(v) {
    v === undefined && (v = this._value);
    return (v & 32768) !== 0;
  }
  isAvailableInStore(v) {
    v === undefined && (v = this._value);
    return (v & 65536) !== 0;
  }
  isRegistrationAvailable(v) {
    v === undefined && (v = this._value);
    return (v & 131072) !== 0;
  }
}
SGStatus.Archived = 1;
SGStatus.Removed = 2;
SGStatus.Started = 4;
SGStatus.Finished = 8;
SGStatus.Group = 16;
SGStatus.Paid = 32;
SGStatus.HasGroupChat = 64;
SGStatus.HasChatWithOrg = 128;
SGStatus.HasChatBetweenMembers = 256;
SGStatus.HasRating = 512;
SGStatus.AvailableImmediately = 1024;
SGStatus.MoneyWithdrawn = 2048;
SGStatus.StartAfterRegistration = 4096;
SGStatus.DisplayReviews = 8192;
SGStatus.MoneyWithdrawStarted = 16384;
SGStatus.ArchivedForFront = 32768;
SGStatus.AvailableInStore = 65536;
SGStatus.RegistrationAvailable = 131072;
SGStatus.constList = [
  "Archived",
  "Removed",
  "Started",
  "Finished",
  "Group",
  "Paid",
  "HasGroupChat",
  "HasChatWithOrg",
  "HasChatBetweenMembers",
  "HasRating",
  "AvailableImmediately",
  "MoneyWithdrawn",
  "StartAfterRegistration",
  "DisplayReviews",
  "MoneyWithdrawStarted",
  "ArchivedForFront",
  "AvailableInStore",
  "RegistrationAvailable",
];
SGStatus.prefix = "SG";
window["SGStatus"] = SGStatus;
class SRVStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isChecking(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
SRVStatus.Archived = 1;
SRVStatus.Removed = 2;
SRVStatus.Checking = 4;
SRVStatus.constList = ["Archived", "Removed", "Checking"];
SRVStatus.prefix = "SRV";
window["SRVStatus"] = SRVStatus;
class TRStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isFirst(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isAccepted(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isSecured(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isDeclined(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isFailed(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isAcceptedLocally(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isWaitingForLocalConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isWaitingForRemoteConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isWaitingForPaymentInfo(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isDeclinedLocally(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
  isHolded(v) {
    v === undefined && (v = this._value);
    return (v & 8192) !== 0;
  }
}
TRStatus.Archived = 1;
TRStatus.Removed = 2;
TRStatus.First = 4;
TRStatus.Accepted = 8;
TRStatus.Secured = 16;
TRStatus.Declined = 32;
TRStatus.Rejected = 64;
TRStatus.Failed = 128;
TRStatus.AcceptedLocally = 256;
TRStatus.WaitingForLocalConfirmation = 512;
TRStatus.WaitingForRemoteConfirmation = 1024;
TRStatus.WaitingForPaymentInfo = 2048;
TRStatus.DeclinedLocally = 4096;
TRStatus.Holded = 8192;
TRStatus.constList = [
  "Archived",
  "Removed",
  "First",
  "Accepted",
  "Secured",
  "Declined",
  "Rejected",
  "Failed",
  "AcceptedLocally",
  "WaitingForLocalConfirmation",
  "WaitingForRemoteConfirmation",
  "WaitingForPaymentInfo",
  "DeclinedLocally",
  "Holded",
];
TRStatus.prefix = "TR";
window["TRStatus"] = TRStatus;
class USRStatus extends Status {
  isOnline(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isBanned(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isFired(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isInternal(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isAuthenticated(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
USRStatus.Archived = 1;
USRStatus.Online = 2;
USRStatus.Banned = 4;
USRStatus.Fired = 8;
USRStatus.Internal = 16;
USRStatus.Authenticated = 32;
USRStatus.Removed = 64;
USRStatus.constList = [
  "Archived",
  "Online",
  "Banned",
  "Fired",
  "Internal",
  "Authenticated",
  "Removed",
];
USRStatus.prefix = "USR";
window["USRStatus"] = USRStatus;
class USGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
USGStatus.Archived = 1;
USGStatus.Removed = 2;
USGStatus.constList = ["Archived", "Removed"];
USGStatus.prefix = "USG";
window["USGStatus"] = USGStatus;
class UTLStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
UTLStatus.Archived = 1;
UTLStatus.Removed = 2;
UTLStatus.constList = ["Archived", "Removed"];
UTLStatus.prefix = "UTL";
window["UTLStatus"] = UTLStatus;
class CARDStatus extends Status {
  isShowAge(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isShowCity(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isShowPhoneNumber(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isShowEmail(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isShowEducation(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isShowWork(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
CARDStatus.ShowAge = 1;
CARDStatus.ShowCity = 2;
CARDStatus.ShowPhoneNumber = 4;
CARDStatus.ShowEmail = 8;
CARDStatus.ShowEducation = 16;
CARDStatus.ShowWork = 32;
CARDStatus.constList = [
  "ShowAge",
  "ShowCity",
  "ShowPhoneNumber",
  "ShowEmail",
  "ShowEducation",
  "ShowWork",
];
CARDStatus.prefix = "CARD";
window["CARDStatus"] = CARDStatus;
class NTFYStatus extends Status {
  isStudentReceiveMessagesCreated(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isStudentReceiveCommentsCreated(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isReceiveLessonActivated(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isReceiveHometaskAnswerRejected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isReceiveHometaskAnswerConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isOrgReceiveMessagesCreated(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isOrgReceiveCommentsCreated(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isReceiveCourseUnderConsideration(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isReceiveCourseConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isReceiveCourseRejected(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isReceiveNewMemberAdded(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isReceiveHometaskAnswerAdded(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
  isReceiveHometaskAnswerUpdated(v) {
    v === undefined && (v = this._value);
    return (v & 4096) !== 0;
  }
  isReceiveNewReviewAdded(v) {
    v === undefined && (v = this._value);
    return (v & 8192) !== 0;
  }
  isReceiveNewReviewAnswerAdded(v) {
    v === undefined && (v = this._value);
    return (v & 16384) !== 0;
  }
  isStudentReceiveStudyGroupStarted(v) {
    v === undefined && (v = this._value);
    return (v & 32768) !== 0;
  }
  isStudentReceiveStudyGroupFinished(v) {
    v === undefined && (v = this._value);
    return (v & 65536) !== 0;
  }
  isOrgReceiveStudyGroupStarted(v) {
    v === undefined && (v = this._value);
    return (v & 131072) !== 0;
  }
  isOrgReceiveStudyGroupFinished(v) {
    v === undefined && (v = this._value);
    return (v & 262144) !== 0;
  }
  isReceiveStudyGroupFinishedWithoutMembers(v) {
    v === undefined && (v = this._value);
    return (v & 524288) !== 0;
  }
  isReceiveReviewUpdated(v) {
    v === undefined && (v = this._value);
    return (v & 1048576) !== 0;
  }
  isReceiveReviewAnswerUpdated(v) {
    v === undefined && (v = this._value);
    return (v & 2097152) !== 0;
  }
  isReceiveReviewRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 4194304) !== 0;
  }
  isReceiveReviewAnswerRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 8388608) !== 0;
  }
  isReceiveTransactionCreated(v) {
    v === undefined && (v = this._value);
    return (v & 16777216) !== 0;
  }
  isReceiveTransactionAccepted(v) {
    v === undefined && (v = this._value);
    return (v & 33554432) !== 0;
  }
  isReceiveTransactionAcceptedLocally(v) {
    v === undefined && (v = this._value);
    return (v & 67108864) !== 0;
  }
  isReceiveTransactionDeclined(v) {
    v === undefined && (v = this._value);
    return (v & 134217728) !== 0;
  }
  isReceiveTransactionSecured(v) {
    v === undefined && (v = this._value);
    return (v & 268435456) !== 0;
  }
  isReceiveTransactionWaitingForLocalConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 536870912) !== 0;
  }
  isReceiveTransactionWaitingForRemoteConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 1073741824) !== 0;
  }
  isReceiveStudyGroupAutoFinishRejected(v) {
    v === undefined && (v = this._value);
    return (v & -2147483648) !== 0;
  }
  isReceiveKYCCreated(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isReceiveKYCUpdated(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isReceiveKYCRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isReceiveKYCVerified(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isReceiveKYCRejected(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isReceiveKYCBlackListUnset(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isReceiveKYCExpired(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isReceiveClientVerifiedSet(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isReceiveClientVerifiedUnset(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
}
NTFYStatus.StudentReceiveMessagesCreated = 1;
NTFYStatus.StudentReceiveCommentsCreated = 2;
NTFYStatus.ReceiveLessonActivated = 4;
NTFYStatus.ReceiveHometaskAnswerRejected = 8;
NTFYStatus.ReceiveHometaskAnswerConfirmed = 16;
NTFYStatus.OrgReceiveMessagesCreated = 32;
NTFYStatus.OrgReceiveCommentsCreated = 64;
NTFYStatus.ReceiveCourseUnderConsideration = 128;
NTFYStatus.ReceiveCourseConfirmed = 256;
NTFYStatus.ReceiveCourseRejected = 512;
NTFYStatus.ReceiveNewMemberAdded = 1024;
NTFYStatus.ReceiveHometaskAnswerAdded = 2048;
NTFYStatus.ReceiveHometaskAnswerUpdated = 4096;
NTFYStatus.ReceiveNewReviewAdded = 8192;
NTFYStatus.ReceiveNewReviewAnswerAdded = 16384;
NTFYStatus.StudentReceiveStudyGroupStarted = 32768;
NTFYStatus.StudentReceiveStudyGroupFinished = 65536;
NTFYStatus.OrgReceiveStudyGroupStarted = 131072;
NTFYStatus.OrgReceiveStudyGroupFinished = 262144;
NTFYStatus.ReceiveStudyGroupFinishedWithoutMembers = 524288;
NTFYStatus.ReceiveReviewUpdated = 1048576;
NTFYStatus.ReceiveReviewAnswerUpdated = 2097152;
NTFYStatus.ReceiveReviewRemoved = 4194304;
NTFYStatus.ReceiveReviewAnswerRemoved = 8388608;
NTFYStatus.ReceiveTransactionCreated = 16777216;
NTFYStatus.ReceiveTransactionAccepted = 33554432;
NTFYStatus.ReceiveTransactionAcceptedLocally = 67108864;
NTFYStatus.ReceiveTransactionDeclined = 134217728;
NTFYStatus.ReceiveTransactionSecured = 268435456;
NTFYStatus.ReceiveTransactionWaitingForLocalConfirmation = 536870912;
NTFYStatus.ReceiveTransactionWaitingForRemoteConfirmation = 1073741824;
NTFYStatus.ReceiveStudyGroupAutoFinishRejected = -2147483648;
NTFYStatus.ReceiveKYCCreated = 1;
NTFYStatus.ReceiveKYCUpdated = 2;
NTFYStatus.ReceiveKYCRemoved = 4;
NTFYStatus.ReceiveKYCVerified = 8;
NTFYStatus.ReceiveKYCRejected = 16;
NTFYStatus.ReceiveKYCBlackListUnset = 32;
NTFYStatus.ReceiveKYCExpired = 64;
NTFYStatus.ReceiveClientVerifiedSet = 128;
NTFYStatus.ReceiveClientVerifiedUnset = 256;
NTFYStatus.constList = [
  "StudentReceiveMessagesCreated",
  "StudentReceiveCommentsCreated",
  "ReceiveLessonActivated",
  "ReceiveHometaskAnswerRejected",
  "ReceiveHometaskAnswerConfirmed",
  "OrgReceiveMessagesCreated",
  "OrgReceiveCommentsCreated",
  "ReceiveCourseUnderConsideration",
  "ReceiveCourseConfirmed",
  "ReceiveCourseRejected",
  "ReceiveNewMemberAdded",
  "ReceiveHometaskAnswerAdded",
  "ReceiveHometaskAnswerUpdated",
  "ReceiveNewReviewAdded",
  "ReceiveNewReviewAnswerAdded",
  "StudentReceiveStudyGroupStarted",
  "StudentReceiveStudyGroupFinished",
  "OrgReceiveStudyGroupStarted",
  "OrgReceiveStudyGroupFinished",
  "ReceiveStudyGroupFinishedWithoutMembers",
  "ReceiveReviewUpdated",
  "ReceiveReviewAnswerUpdated",
  "ReceiveReviewRemoved",
  "ReceiveReviewAnswerRemoved",
  "ReceiveTransactionCreated",
  "ReceiveTransactionAccepted",
  "ReceiveTransactionAcceptedLocally",
  "ReceiveTransactionDeclined",
  "ReceiveTransactionSecured",
  "ReceiveTransactionWaitingForLocalConfirmation",
  "ReceiveTransactionWaitingForRemoteConfirmation",
  "ReceiveStudyGroupAutoFinishRejected",
  "ReceiveKYCCreated",
  "ReceiveKYCUpdated",
  "ReceiveKYCRemoved",
  "ReceiveKYCVerified",
  "ReceiveKYCRejected",
  "ReceiveKYCBlackListUnset",
  "ReceiveKYCExpired",
  "ReceiveClientVerifiedSet",
  "ReceiveClientVerifiedUnset",
];
NTFYStatus.prefix = "NTFY";
window["NTFYStatus"] = NTFYStatus;
class LSNStatus extends Status {
  isActive(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isHasHometask(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
LSNStatus.Active = 1;
LSNStatus.HasHometask = 2;
LSNStatus.constList = ["Active", "HasHometask"];
LSNStatus.prefix = "LSN";
window["LSNStatus"] = LSNStatus;
class ANSStatus extends Status {
  isSendForCheck(v) {
    v === undefined && (v = this._value);
    return (v & 1) !== 0;
  }
  isConfirmed(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isChecked(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
ANSStatus.SendForCheck = 1;
ANSStatus.Confirmed = 2;
ANSStatus.Rejected = 4;
ANSStatus.Checked = 8;
ANSStatus.constList = ["SendForCheck", "Confirmed", "Rejected", "Checked"];
ANSStatus.prefix = "ANS";
window["ANSStatus"] = ANSStatus;
window.kv = {
  services: {
    study_group: {
      can_finish_errors: {
        root: {
          already_finished: "Поток уже завершен",
          allocated_time_has_not_passed:
            "Время, отведенное на курс, еще не прошло",
          members_with_not_started_lessons: "Не все уроки стартовали",
          not_all_homework_is_checked: "Есть непроверенные домашние задания",
        },
      },
      const: [
        "Removed",
        "Started",
        "Finished",
        "Group",
        "Paid",
        "HasGroupChat",
        "HasChatWithOrg",
        "HasChatBetweenMembers",
        "HasRating",
        "AvailableImmediately",
        "MoneyWithdrawn",
        "StartAfterRegistration",
        "DisplayReviews",
        "MoneyWithdrawStarted",
        "ArchivedForFront",
        "AvailableInStore",
        "RegistrationAvailable",
      ],
      elastic: {
        state: {
          mapping: {
            status: "integer",
            created_at: "date",
            created_by: "term",
            created_by_lastname: "short_text",
            created_by_firstname: "short_text",
            created_by_status: "integer",
            started_at: "date",
            finished_at: "date",
            title: "short_text",
            description: "long_text",
            crs_id: "term",
            crs_id_title: "short_text",
            crs_id_description: "long_text",
            course_price: { amount: "integer", currency: "term" },
            start_type: "term",
            interval: "term",
            member_count: "integer",
            max_member_count: "integer",
            type: "term",
            crs_id_category: "term",
            crs_id_unique_view_count: "integer",
            crs_id_avg_rating: "double",
            view_count: "integer",
            course_type: "term",
            available_until: "date",
            register_before: "date",
          },
          resolve: {
            query: {
              fields: [
                "created_by.last_name",
                "created_by.first_name",
                "created_by.status",
                "crs_id.title",
                "crs_id.description",
                "crs_id.category",
                "crs_id.avg_rating",
                "crs_id.unique_view_count",
              ],
            },
            fields: [
              {
                new_field: "created_by_lastname",
                field: "created_by",
                path: "last_name",
              },
              {
                new_field: "created_by_firstname",
                field: "created_by",
                path: "first_name",
              },
              {
                new_field: "created_by_middlename",
                field: "created_by",
                path: "status",
              },
              { new_field: "crs_id_title", field: "crs_id", path: "title" },
              {
                new_field: "crs_id_description",
                field: "crs_id",
                path: "description",
              },
              {
                new_field: "crs_id_category",
                field: "crs_id",
                path: "category",
              },
              {
                new_field: "crs_id_avg_rating",
                field: "crs_id",
                path: "avg_rating",
              },
              {
                new_field: "crs_id_unique_view_count",
                field: "crs_id",
                path: "unique_view_count",
              },
            ],
          },
          function_score: {
            field_value_factor: {
              field: "crs_id_unique_view_count",
              modifier: "none",
              missing: 1,
            },
            boost_mode: "multiply",
          },
          pipeline: {
            id: "stidy_group_fix_null_value",
            body: {
              processors: [
                {
                  set: {
                    field: "crs_id_unique_view_count",
                    value: 0,
                    override: false,
                  },
                },
                {
                  set: {
                    field: "crs_id_avg_rating",
                    value: -1,
                    override: false,
                  },
                },
                {
                  script: {
                    lang: "painless",
                    ignore_failure: true,
                    source:
                      "if (ctx['course_type'] == 'group') {ctx['available_until'] = ctx['started_at'];} else {if (ctx['type'] != 'child') {ctx['available_until'] = ctx['register_before'];}}",
                  },
                },
              ],
            },
          },
        },
      },
      foreign_const: ["course", "member", "chat", "client"],
      lesson_interval: {
        "once-a-day": [1, "days"],
        "once-a-week": [1, "weeks"],
        test: [5, "minutes"],
      },
      lesson_interval_dictionary: {
        root: {
          "once-a-day": "Раз в сутки",
          "once-a-week": "Раз в неделю",
          test: "Каждые 5 минут",
        },
      },
      notification_list: [
        "StudyGroupStarted",
        "StudyGroupFinished",
        "StudyGroupFinishedWithoutMembers",
        "StudyGroupCreated",
        "StudyGroupAutoFinishRejected",
      ],
      pg: {
        tables: [
          {
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "_hid", type: "bigint" },
                { name: "status", type: "INT" },
                { name: "_offset", type: "bigint" },
                { name: "crs_id", type: "varchar(256)" },
                { name: "created_by", type: "varchar(256)" },
                { name: "created_at", type: "timestamp with time zone" },
                { name: "title", type: "text" },
                { name: "interval", type: "varchar(256)" },
                { name: "start_type", type: "varchar(256)" },
                { name: "max_member_count", type: "INT" },
                { name: "member_count", type: "INT" },
                { name: "started_at", type: "timestamp with time zone" },
                { name: "finished_at", type: "timestamp with time zone" },
                { name: "cht_id", type: "varchar(256)" },
                { name: "lessons", type: "jsonb[]" },
                { name: "course_type", type: "varchar(256)" },
                { name: "type", type: "varchar(256)" },
                { name: "sg_id", type: "varchar(256)" },
                { name: "withdraw_tr_id", type: "varchar(256)" },
              ],
              options: ["constraint pk_study_group primary key (_id)"],
              indices: [{ field: "crs_id" }, { field: "sg_id" }],
            },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "01:21:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "11:03:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
        ],
      },
      prefix: "SG",
      resolve: {
        notify: [
          "_id",
          "title",
          "course_type",
          "author_img_id.0.url",
          "author_img_id.0.small_img_url",
          "author_nickname",
          "member_count",
          "started_at",
          "finished_at",
          "status",
          "counter",
          "mb_id",
          "cl_id.last_name",
          "cl_id.first_name",
          "cl_id.nickname",
          "cl_id._id",
        ],
      },
    },
    course: {
      categories: {
        root: {
          development: "Разработка",
          business: "Бизнес",
          "finance-and-accounting": "Финансы и бухгалтерский учет",
          "IT-and-software": "ИТ и ПО",
          "personal growth": "Личностный рост",
          design: "Дизайн",
          marketing: "Маркетинг",
          "life-style": "Стиль жизни",
          "photography-and-video": "Фотография и видео",
          "health-and-fitness": "Здоровье и фитнес",
          music: "Музыка",
          academic: "Учебные и академические дисциплины",
        },
      },
      const: [
        "Removed",
        "Confirmed",
        "Rejected",
        "UnderConsideration",
        "PendingConsideration",
        "Hidden",
      ],
      elastic: {
        state: {
          mapping: {
            status: "integer",
            created_at: "date",
            created_by: "term",
            created_by_lastname: "short_text",
            created_by_firstname: "short_text",
            created_by_status: "integer",
            started_at: "date",
            finished_at: "term",
            title: "short_text",
            description: "long_text",
            unique_view_count: "integer",
            avg_rating: "double",
          },
          resolve: {
            query: {
              fields: [
                "created_by.last_name",
                "created_by.first_name",
                "created_by.status",
              ],
            },
            fields: [
              {
                new_field: "created_by_lastname",
                field: "created_by",
                path: "last_name",
              },
              {
                new_field: "created_by_firstname",
                field: "created_by",
                path: "first_name",
              },
              {
                new_field: "created_by_middlename",
                field: "created_by",
                path: "status",
              },
            ],
          },
        },
      },
      foreign_const: ["member", "study_group"],
      metrics: ["course_unique_view_count"],
      notification_list: [
        "CourseUnderConsiderationSet",
        "CourseConfirmed",
        "CourseRejected",
      ],
      pg: {
        tables: [
          {
            structure: {
              fields: [
                { name: "_id", type: "varchar(256)", options: "NOT NULL" },
                { name: "_hid", type: "bigint" },
                { name: "status", type: "INT" },
                { name: "_offset", type: "bigint" },
                { name: "created_by", type: "varchar(256)" },
                { name: "created_at", type: "timestamp with time zone" },
                { name: "updated_by", type: "varchar(256)" },
                { name: "updated_at", type: "timestamp with time zone" },
                { name: "confirmed_by", type: "varchar(256)" },
                { name: "confirmed_at", type: "timestamp with time zone" },
                { name: "title", type: "text" },
                { name: "started_at", type: "timestamp with time zone" },
              ],
              options: ["constraint pk_course primary key (_id)"],
              indices: [{ field: "created_by" }],
            },
            maintenance_schedule: [
              {
                name: "vacuum_full",
                at: "01:15:00Z",
                interval: 604800,
                command: "VACUUM (full, analyze) %table",
              },
              {
                name: "vacuum_analyze",
                at: "11:00:00Z",
                interval: 86400,
                command: "VACUUM (analyze) %table",
              },
            ],
          },
        ],
      },
      prefix: "CRS",
      resolve: {
        state: [
          "_id",
          "title",
          "description",
          "created_by._id",
          "created_by.first_name",
          "created_by.last_name",
          "created_at",
          "confirmed_by._id",
          "confirmed_by.first_name",
          "confirmed_by.last_name",
          "confirmed_at",
          "updated_by._id",
          "updated_by.first_name",
          "updated_by.last_name",
          "updated_at",
          "intro_blocks.*.type",
          "intro_blocks.*.value._id",
          "intro_blocks.*.value.filename",
          "intro_blocks.*.value.small_filename",
          "intro_blocks.*.value.medium_filename",
          "lessons.*.title",
          "lessons.*.blocks.*.type",
          "lessons.*.blocks.*.value",
          "lessons.*.blocks.*.value._id",
          "lessons.*.blocks.*.value.filename",
          "lessons.*.blocks.*.value.small_filename",
          "lessons.*.blocks.*.value.medium_filename",
          "lessons.*.hometasks.*.blocks.*.type",
          "lessons.*.hometasks.*.blocks.*.value",
          "lessons.*.hometasks.*.blocks.*.value._id",
          "lessons.*.hometasks.*.blocks.*.value.filename",
          "lessons.*.hometasks.*.blocks.*.value.small_filename",
          "lessons.*.hometasks.*.blocks.*.value.medium_filename",
          "final_lesson.*.title",
          "final_lesson.*.blocks.*.type",
          "final_lesson.*.blocks.*.type",
          "final_lesson.*.blocks.*.value",
          "final_lesson.*.blocks.*.value._id",
          "final_lesson.*.blocks.*.value.filename",
          "final_lesson.*.blocks.*.value.small_filename",
          "final_lesson.*.blocks.*.value.medium_filename",
          "final_lesson.*.hometasks.*.blocks.*.type",
          "final_lesson.*.hometasks.*.blocks.*.value",
          "final_lesson.*.hometasks.*.blocks.*.value._id",
          "final_lesson.*.hometasks.*.blocks.*.value.filename",
          "final_lesson.*.hometasks.*.blocks.*.value.small_filename",
          "final_lesson.*.hometasks.*.blocks.*.value.medium_filename",
          "introductory_lesson.*.title",
          "introductory_lesson.*.blocks.*.type",
          "introductory_lesson.*.blocks.*.type",
          "introductory_lesson.*.blocks.*.value",
          "introductory_lesson.*.blocks.*.value._id",
          "introductory_lesson.*.blocks.*.value.filename",
          "introductory_lesson.*.blocks.*.value.small_filename",
          "introductory_lesson.*.blocks.*.value.medium_filename",
          "introductory_lesson.*.hometasks.*.blocks.*.type",
          "introductory_lesson.*.hometasks.*.blocks.*.value",
          "introductory_lesson.*.hometasks.*.blocks.*.value._id",
          "introductory_lesson.*.hometasks.*.blocks.*.value.filename",
          "introductory_lesson.*.hometasks.*.blocks.*.value.small_filename",
          "introductory_lesson.*.hometasks.*.blocks.*.value.medium_filename",
          "status",
          "removed_at",
        ],
        notify: [
          "_id",
          "title",
          "rejected_at",
          "reject_comment",
          "confirmed_at",
          "status",
        ],
      },
    },
  },
  customCldrData: {
    main: {
      root: {
        numbers: {
          currencies: {
            XBT: {
              displayName: "Bitcoin",
              "displayName-count-one": "bitcoin",
              "displayName-count-other": "bitcoins",
              symbol: "XBT",
              "symbol-alt-narrow": "XBT",
            },
          },
        },
      },
      ru: {
        numbers: {
          currencies: {
            XBT: {
              displayName: "Биткоин",
              "displayName-count-one": "биткоин",
              "displayName-count-few": "биткоина",
              "displayName-count-many": "биткоинов",
              "displayName-count-other": "биткоина",
              symbol: "XBT",
              "symbol-alt-narrow": "XBT",
            },
          },
        },
      },
    },
  },
  prefixMap: {
    SCH: "search",
    USR: "user",
    UTL: "utils",
    FILE: "file",
    BUS: "bus",
    I18N: "i18n",
    PLC: "policy",
    USG: "user_group",
    RS: "resource",
    SCD: "scheduler",
    NF: "notification",
    SSN: "session",
    CL: "client",
    MSG: "message",
    CHT: "chat",
    ACT: "activity",
    PRE: "pretension",
    TR: "transaction",
    CRS: "course",
    MB: "member",
    CHM: "chat_member",
    SG: "study_group",
    SRV: "survey",
    KYC: "kyc",
  },
  uploadsBasePath: "https://bh.nsolid.ru",
  locales: [
    "ar",
    "bg",
    "ca",
    "cs",
    "da",
    "de",
    "el",
    "en",
    "es",
    "eu",
    "fa",
    "fi",
    "fr",
    "ga",
    "gl",
    "hi",
    "hu",
    "hy",
    "id",
    "it",
    "ja",
    "jv",
    "ko",
    "ku",
    "lt",
    "lv",
    "nl",
    "no",
    "pt",
    "ro",
    "ru",
    "sv",
    "th",
    "tr",
    "zh",
  ],
  denominationCoef: { XBT: 1000000000, FUN: 100, "*": 1000 },
  currencies: [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLF",
    "CLP",
    "CNH",
    "CNY",
    "COP",
    "CRC",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GGP",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "IMP",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JEP",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "SSP",
    "STD",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XBT",
    "XCD",
    "XDR",
    "XOF",
    "XPD",
    "XPF",
    "XPT",
    "YER",
    "ZAR",
    "ZMW",
    "ZWL",
    "bonus_points",
  ],
  countries: [
    "AU",
    "AT",
    "AZ",
    "AX",
    "AL",
    "DZ",
    "AS",
    "AI",
    "AO",
    "AD",
    "AG",
    "AR",
    "AM",
    "AW",
    "AF",
    "BS",
    "BD",
    "BB",
    "BH",
    "BY",
    "BZ",
    "BE",
    "BJ",
    "BM",
    "BG",
    "BO",
    "BA",
    "BW",
    "BR",
    "IO",
    "BN",
    "BF",
    "BI",
    "BT",
    "VU",
    "VA",
    "GB",
    "HU",
    "VE",
    "VG",
    "VI",
    "TL",
    "VN",
    "GA",
    "HT",
    "GY",
    "GM",
    "GH",
    "GP",
    "GT",
    "GN",
    "GW",
    "DE",
    "GI",
    "HN",
    "HK",
    "GD",
    "GL",
    "GR",
    "GE",
    "GU",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EG",
    "ZM",
    "EH",
    "ZW",
    "IL",
    "IN",
    "ID",
    "JO",
    "IQ",
    "IR",
    "IS",
    "ES",
    "IT",
    "YE",
    "CV",
    "KZ",
    "KY",
    "KH",
    "CM",
    "CA",
    "QA",
    "KE",
    "CY",
    "KG",
    "KI",
    "CN",
    "KP",
    "CC",
    "CO",
    "KM",
    "CG",
    "CD",
    "CR",
    "CI",
    "CU",
    "KW",
    "LA",
    "LV",
    "LS",
    "LR",
    "LB",
    "LY",
    "LT",
    "LI",
    "LU",
    "MU",
    "MR",
    "MG",
    "YT",
    "MO",
    "MK",
    "MW",
    "MY",
    "ML",
    "MV",
    "MT",
    "MA",
    "MQ",
    "MH",
    "MX",
    "MZ",
    "MD",
    "MC",
    "MN",
    "MS",
    "MM",
    "NA",
    "NR",
    "NP",
    "NE",
    "NG",
    "NL",
    "NI",
    "NU",
    "NZ",
    "NC",
    "NO",
    "NF",
    "CX",
    "SH",
    "CK",
    "TC",
    "AE",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PL",
    "PT",
    "PR",
    "KR",
    "RE",
    "RU",
    "RW",
    "RO",
    "SV",
    "WS",
    "SM",
    "ST",
    "SA",
    "SZ",
    "MP",
    "SC",
    "PM",
    "SN",
    "VC",
    "KN",
    "LC",
    "RS",
    "SG",
    "SY",
    "SK",
    "SI",
    "US",
    "SB",
    "SO",
    "SD",
    "SR",
    "SL",
    "TJ",
    "TH",
    "TW",
    "TZ",
    "TG",
    "TK",
    "TO",
    "TT",
    "TV",
    "TN",
    "TM",
    "TR",
    "UG",
    "UZ",
    "UA",
    "WF",
    "UY",
    "FO",
    "FM",
    "FJ",
    "PH",
    "FI",
    "FK",
    "FR",
    "GF",
    "PF",
    "HR",
    "CF",
    "TD",
    "ME",
    "CZ",
    "CL",
    "CH",
    "SE",
    "SJ",
    "LK",
    "EC",
    "GQ",
    "ER",
    "EE",
    "ET",
    "ZA",
    "JM",
    "JP",
  ],
  geocodingApiKey: { google: "AIzaSyCzHIBJuzlvzdOlx1KrdzkZZvffQv2rvps" },
  googleTranslationApiKey: "AIzaSyA0zDa3QLkoSDEvu6Mx0uL_beCir7Lerwk",
  kycType: { person: ["passport"] },
  _endpointToPrefix: {
    searches: "SCH",
    users: "USR",
    utils: "UTL",
    files: "FILE",
    buses: "BUS",
    i18n: "I18N",
    policies: "PLC",
    user_groups: "USG",
    resources: "RS",
    schedulers: "SCD",
    notifications: "NF",
    sessions: "SSN",
    clients: "CL",
    messages: "MSG",
    chats: "CHT",
    activities: "ACT",
    pretensions: "PRE",
    transactions: "TR",
    courses: "CRS",
    members: "MB",
    chat_members: "CHM",
    study_groups: "SG",
    surveys: "SRV",
    kyc: "KYC",
  },
  _prefixToEndpoint: {
    SCH: "searches",
    USR: "users",
    UTL: "utils",
    FILE: "files",
    BUS: "buses",
    I18N: "i18n",
    PLC: "policies",
    USG: "user_groups",
    RS: "resources",
    SCD: "schedulers",
    NF: "notifications",
    SSN: "sessions",
    CL: "clients",
    MSG: "messages",
    CHT: "chats",
    ACT: "activities",
    PRE: "pretensions",
    TR: "transactions",
    CRS: "courses",
    MB: "members",
    CHM: "chat_members",
    SG: "study_groups",
    SRV: "surveys",
    KYC: "kyc",
  },
  prefix_map: {
    SCH: "search",
    USR: "user",
    UTL: "utils",
    FILE: "file",
    BUS: "bus",
    I18N: "i18n",
    PLC: "policy",
    USG: "user_group",
    RS: "resource",
    SCD: "scheduler",
    NF: "notification",
    SSN: "session",
    CL: "client",
    MSG: "message",
    CHT: "chat",
    ACT: "activity",
    PRE: "pretension",
    TR: "transaction",
    CRS: "course",
    MB: "member",
    CHM: "chat_member",
    SG: "study_group",
    SRV: "survey",
    KYC: "kyc",
  },
};
window.calcDenomination = function (_from, _to) {
  const from =
    window.kv.denominationCoef[_from] || window.kv.denominationCoef["*"];
  const to = window.kv.denominationCoef[_to] || window.kv.denominationCoef["*"];
  return to / from;
};
window.baseToMinor = function (value, currency) {
  const coef =
    window.kv.denominationCoef[currency] || window.kv.denominationCoef["*"];
  return Math.round(value * coef);
};
window.minorToBase = function (value, currency) {
  const coef =
    window.kv.denominationCoef[currency] || window.kv.denominationCoef["*"];
  return value / coef;
};
