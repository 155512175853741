import { ArrowLeft } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import BlockView from "../../../components/BlockView/BlockView";
import { getClasses } from "../../../constants/theme";
import courseForm from "./../../../forms/createNewCourse";

export default function StartPagePreview(props) {
  const classes = getClasses(dynamicStyle);
  const navigate = useNavigate();
  const { id, index, type, hindex } = useParams();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <div className={classes("title")}>{courseForm.get("title")}</div>
        <div className={classes("description")}>
          {courseForm.get("description")}
        </div>
        <BlockView blocks={courseForm.get(`intro_blocks`)} />
      </div>
      <div className={classes("footer")}>
        <div className={classes("footerWrapper")}>
          <div className={classes("previewButton")} onClick={goBack}>
            Закрыть превью
          </div>
        </div>
      </div>
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    description: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      marginBottom: 8,
    },
    backIcon: {
      color: Colors.content,
      marginRight: 16,
      cursor: "pointer",
    },
    title: {
      ...Fonts.title1,
      alignItems: "center",
      display: "flex",
      marginBottom: 16,
    },
    footerWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 8,
    },
    footer: {
      position: "fixed",
      right: 40,
      bottom: 32,
    },
    previewButton: {
      ...Fonts.headline1,
      backgroundColor: Colors.neutral1,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      height: 48,
      borderRadius: 25,
      color: Colors.white,
      paddingLeft: 64,
      paddingRight: 64,
      marginRight: 16,
      cursor: "pointer",
    },
  };
};
