import * as LightColor from "./light/Color";
import * as LightFonts from "./light/Fonts";
import * as DarkColor from "./dark/Color";
import * as DarkFonts from "./dark/Fonts";

import reactToCSS from "react-style-object-to-css";
import { getUID } from "../../utils";
import React, { useEffect, useState } from "react";
import Emitter from "../../stores/core/emitter";

const cssEmitter = new Emitter();
const useStylesCache = true;
const currentTheme = "light";

const cache = {
  dark: new Map(),
  light: new Map(),
};

const classCache = {
  dark: new Map(),
  light: new Map(),
};

export const useTheme = (fn = () => {}) => {

  if (cache[currentTheme].has(fn) && useStylesCache) {
    return cache[currentTheme].get(fn);
  } else {
    const styles = fn(
      currentTheme === "dark" ? DarkColor : LightColor,
      currentTheme === "dark" ? DarkFonts : LightFonts
    );

    useStylesCache && cache[currentTheme].set(fn, styles);

    return styles;
  }
};

export const getTheme = (fn = () => {}) => {

  if (cache[currentTheme].has(fn) && useStylesCache) {
    return cache[currentTheme].get(fn);
  } else {
    const styles = fn(
      currentTheme === "dark" ? DarkColor : LightColor,
      currentTheme === "dark" ? DarkFonts : LightFonts
    );

    useStylesCache && cache[currentTheme].set(fn, styles);

    return styles;
  }
};

export const getClasses = (fn = () => {}) => {

  if (classCache[currentTheme].has(fn) && useStylesCache) {
    return classCache[currentTheme].get(fn);
  } else {
    const [css, getStyles] = classWrapper(
      fn(
        currentTheme === "dark" ? DarkColor : LightColor,
        currentTheme === "dark" ? DarkFonts : LightFonts
      )
    );

    useStylesCache && classCache[currentTheme].set(fn, getStyles);

    cssEmitter.emit("add", css);

    return getStyles;
  }
};

const classWrapper = (styles) => {
  const css = [];
  const alias = {};

  for (let key in styles) {
    const [pskey, ps] = key.split(":");

    const value = styles[key];

    if (alias[pskey] === undefined) {
      const pKey = getUID();
      alias[pskey] = pKey;
      css.push(`
      .${pKey}${ps ? `:${ps}` : ``} /* ${key} */ {
        ${reactToCSS(value)}
      }
    `);
    } else if (ps !== undefined) {
      css.push(`
      .${alias[pskey]}${ps ? `:${ps}` : ``} {
        ${reactToCSS(value)}
      }
    `);
    }
  }

  return [
    css.join(" "),
    (...names) => {
      const result = names.filter((c) => !!c).map((c) => alias[c]);

      return result.join(" ");
    },
  ];
};
const styles = [];

export const CssRenderComponent = (props) => {
  const [state, setState] = useState(styles);

  useEffect(() => {
    const off = cssEmitter.on("add", (a) => {
      styles.push(a);
      setState([...styles]);
    });

    return off;
  }, []);

  return (
    <>
      {state.map((_class, index) => {
        return <React.Fragment key={index}>{_class}</React.Fragment>;
      })}
    </>
  );
};
