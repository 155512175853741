import "./App.css";
import Dialog from "./components/Dialog";
import useStore from "./hooks/useStore";
import Navigation from "./navigation";

function App() {
  const ready = useStore();

  if (!ready) return null;

  return (
    <>
      <Navigation />
      <Dialog />
    </>
  );
}

export default App;
