import moment from "moment";
import { useTheme } from "../../../constants/theme";

export default function ForModerationItem({ item,onClick }) {
  const styles = useTheme(dynamicStyle);

  return (
    <div style={styles.container} onClick={onClick}>
      <div style={styles.title}>{item.title}</div>
      <div style={styles.subtitle}>Создан {moment(item.created_at).format('DD.MM.YYYY HH:mm')}</div>
    </div>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    container: {
      marginTop:8,
      backgroundColor: Colors.neutral4,
      borderRadius: 10,
      paddingLeft:16,
      paddingRight:16,
      paddingTop:12,
      paddingBottom:12,
      cursor:'pointer'
    },
    title: {
      ...Fonts.subhead1,
      color: Colors.neutral1,
      marginBottom:2
    },
    subtitle: {
      ...Fonts.footnote,
      color: Colors.neutral2,
    },
  };
};
