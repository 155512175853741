import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { useTheme } from "../../constants/theme";
import Deauth from "../../methods/deauth";
import DraftList from "./SubPages/DraftList";

export default function CoursesListPage() {
  const styles = useTheme(dynamicStyle);
  const navigate = useNavigate();

  const goToCreateCourse = () => {
    navigate("/course/new/menu");
  };


  
  // const goToCreateCourse = async () => {
  //   try {
  //     const res = await createCourse({});
  //     navigate(`/course/${res._id}/menu`);
  //     console.log(res)
  //   } catch (err) {
  //     errorHandler(err);
  //   }
  // };

  const deauth = () => {
    Deauth(navigate);
  };

  return (
    <>
      <div style={styles.appbar}>
        <Logo containerStyle={styles.logoWrapper} />
        <div style={styles.appItemFlex1}></div>
        <div style={styles.submenu}>
          <div style={styles.addButtonStyle} onClick={goToCreateCourse}>
            Добавить курс
          </div>
          <div onClick={deauth} style={styles.exitButtonStyle}>
            Выйти
          </div>
        </div>
      </div>

      <div style={styles.listContiner}>
        <div style={styles.listWrapper}>
          <div style={styles.title}>Шаблоны</div>
          <DraftList />
        </div>
      </div>
    </>
  );
}

const dynamicStyle = (Colors, Fonts, params = {}) => {
  return {
    title: {
      ...Fonts.title1,
      color: Colors.neutral1,
      marginBottom: 24,
    },
    listWrapper: {
      width: 500,
    },
    listContiner: {
      display: "flex",
      flex: 1,
      flexDirection: "conumn",
      width: "100%",
      justifyContent: "center",
    },
    addButtonStyle: {
      ...Fonts.subhead2,
      backgroundColor: Colors.color1,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 16,
      color: Colors.white,
      cursor: "pointer",
    },
    exitButtonStyle: {
      ...Fonts.subhead2,
      paddingLeft: 28,
      paddingRight: 28,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 16,
      color: Colors.neutral1,
      cursor: "pointer",
      marginLeft: 16,
    },
    submenu: {
      justifyContent: "center",
      display: "flex",
    },
    appItemFlex1: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
    },
    appbar: {
      marginLeft: 8,
      marginRight: 8,

      height: 78,
      display: "flex",
      alignItems: "center",
    },
  };
};
