import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "../../constants/theme";
import { makeImageUrl } from "../../utils";
import { Loader } from "../../stores/Loader";
import { RefreshCw, X, Check } from "react-feather";
import { errorHandler, removeFile } from "../../api";
import { Settings } from "../../stores/Settings";

export const VideoLoader = function (props) {
  const styles = useTheme(props.styles ? props.styles : dynamicStyle);
  const [video, setVideo] = useState(props.video);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(props?.video?._id);
  const [error, setError] = useState(void 0);
  const fFile = useRef();

  const videoViewRef = useRef();

  useEffect(() => {
    //props.autoOpen && video.tmpid === undefined && openVideoPicker();
  }, []);

  useEffect(() => {
    if (!video?.tmpid) return;

    const off = Loader.get(
      `tasks.${video.tmpid}.progress:${props.blockId}`,
      void 0,
      ({ next }) => {
        next !== void 0 && setProgress(next);
      }
    );

    const off2 = Loader.get(
      `tasks.${video.tmpid}.error:${props.blockId}`,
      void 0,
      ({ next }) => {
        next !== void 0 && setError(next);
      }
    );

    return () => {
      off();
      off2();
    };
  }, [video?.tmpid, props.blockId]);

  useEffect(() => {
    if (!video?.tmpid) return;

    return Loader.get(
      `tasks.${video?.tmpid}.ready:${props.blockId}`,
      video,
      ({ next, prev, def }) => {
        if (next === true) {
          const timg = Loader.get(`tasks.${video?.tmpid}`);
          setLoaded(true);
          setVideo(timg);
          props.onComplete(timg);
          Loader.remove(`tasks.${video?.tmpid}`);
        }
      }
    );
  }, [video, video?.tmpid, props.blockId, props]);

  const reloadFile = async () => {
    const file = video.file || video;
    setLoaded(false);
    setProgress(0);

    await Loader.removeWherReload(video.tmpid);
    const tmpfile = Loader.load(file, {
      blockId: props.blockId,
      prefix: props.prefix,
    });

    props.onComplete(tmpfile);

    setVideo({
      url: file.path,
      tmpid: tmpfile.tmpid,
      file: file,
    });
    //Удаляем файл
    //Удаляем задачу
    //создаем новую с тем же uuid
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (video && video._id !== undefined && props.removeWhewChange) {
      removeFile(video._id).catch((e) => errorHandler(e));
    }

    const MaxFileSize = Settings.get("me.subscription.max_file_size");

    if (file.size > Settings.get("me.subscription.max_file_size")) {
      Settings.emit("local_notification", {
        level: "error",
        text: "Превышен максимальный размер файла",
        subtext: `Максимальный размер файла ${(
          MaxFileSize /
          1024 /
          1024
        ).toFixed(2)}Мб`,
      });
      return props.onItemRemoved(video, props.blockId);
    }

    setLoaded(false);
    setProgress(0);

    const tmpfile = Loader.load(file, {
      blockId: props.blockId,
      prefix: props.prefix,
    });

    props.onComplete(tmpfile);

    setVideo({
      local_url: URL.createObjectURL(file),
      tmpid: tmpfile.tmpid,
      file,
    });
  };
  const onOpenFileBrowser = () => {
    fFile.current.click();
  };

  return (
    <div>
      {Object.keys(video).length > 0 ? (
        <div
          style={{
            ...styles.imageContainer,
            ...(error ? styles.errorContainer : {}),
          }}
        >
          <video
            preload={"metadata"}
            controls={"controls"}
            //poster={makeImageUrl(video, void 0, "video_preview", "filename")}
            style={styles.image}
            ref={videoViewRef}
          >
            <source
              src={
                video.local_url
                  ? video.local_url
                  : makeImageUrl(video, void 0, "filename")
              }
            />
          </video>

          {!loaded && props.disabled !== true && error === undefined && (
            <div style={styles.progressWrapper}>
              <div style={styles.progressText}>{progress} %</div>
            </div>
          )}

          {loaded && (
            <div style={styles.readyWrapper}>
              <Check style={styles.readyIcon} name={"check"} />
            </div>
          )}

          {props.onItemRemoved && (
            <div
              onClick={() => {
                props.onItemRemoved &&
                  props.onItemRemoved(video, props.blockId);
              }}
              style={styles.removeWrapper}
            >
              <X style={styles.removeIcon} name={"x"} />
            </div>
          )}

          {error && (
            <div onClick={reloadFile} style={styles.reloadWrapper}>
              <RefreshCw style={styles.reloadIcon} name={"refresh-cw"} />
            </div>
          )}
        </div>
      ) : (
        <div disabled={props.disabled} onClick={onOpenFileBrowser}>
          {props.Component}
        </div>
      )}
      <input
        ref={fFile}
        accept="video/mp4,video/x-m4v,video/*"
        style={styles.fileInput}
        type="file"
        onChange={onFileChange}
      />
    </div>
  );
};

VideoLoader.defaultProps = {
  video: void 0,
};

const dynamicStyle = (Colors, Fonts) => {
  return {
    fileInput: {
      display: "none",
    },
    reloadIcon: {},
    reloadWrapper: {
      backgroundColor: Colors.accent2,
      position: "absolute",
    },
    errorContainer: {
      borderWidth: 1,
      borderColor: "#f00",
    },
    playWrapper: {
      display: "flex",
      position: "absolute",
      backgroundColor: Colors.modal,
      width: 48,
      height: 48,
      borderRadius: 32,
      justifyContent: "center",
      alignItems: "center",
    },
    playIcon: {
      color: Colors.absoluteWhite,
      fontSize: 28,
      marginLeft: 4,
    },
    progressWrapper: {
      width: 48,
      height: 48,
      borderRadius: 32,
      display: "flex",

      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      backgroundColor: Colors.modal,
    },
    fullscreenWrapper: {
      backgroundColor: Colors.modal,
      width: 30,
      height: 30,
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 4,
      position: "absolute",
      right: 0,
      top: 0,
      display: "flex",
    },
    fullscreenIcon: {
      color: Colors.absoluteWhite,
      fontSize: 20,
    },
    progressText: {
      marginTop: 2,
      ...Fonts.footnote,
      color: Colors.absoluteWhite,
    },
    readyIcon: {
      color: Colors.absoluteWhite,
      fontSize: 14,
    },
    readyWrapper: {
      backgroundColor: Colors.modal,
      width: 20,
      height: 20,
      alignItems: "center",
      justifyContent: "center",
      borderBottomRightRadius: 4,
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
    },
    imageContainer: {
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      overflow: "hidden",
      position: "relative",
      display: "flex",
    },
    refreshWrapper: {
      backgroundColor: Colors.modal,
      width: 50,
      height: 50,
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: 16,
      position: "absolute",
      display: "flex",
      left: 0,
      bottom: 0,
    },
    refreshIcon: {
      color: Colors.absoluteWhite,
      fontSize: 22,
    },
    image: {
      height: 200,
      width: "100%",
      flex: 1,
      borderRadius: 10,
      objectFit: "contain",
    },
  };
};
